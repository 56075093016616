import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LPButton from '../../components/ui/button/LPButton';
import { SVGlogo } from '../../assets/PackageSVG';

import cl from './LoginPage.module.scss';
import { useSelector } from 'react-redux';
import {
  isAuthorizedSelector,
  isProdSelector,
  tokenSelector,
} from '../../redux/selectors';

const LoginPage = () => {
  const isAuthorized = useSelector(isAuthorizedSelector);

  const token = useSelector(tokenSelector);

  const isProd = useSelector(isProdSelector);

  const navigate = useNavigate();

  //

  useEffect(() => {
    if (isAuthorized && token) {
      navigate('/profile');
    }
  });

  //

  const onButtonClick = () => {
    window.location.href = `https://app.listperfectly.com/?type=auth&response_type=token&redirect_uri=${window.location.origin}/authentication`;
  };

  const onButtonClick2 = () => {
    window.location.href = `https://apptest.listperfectly.com/?type=auth&response_type=token&redirect_uri=${window.location.origin}/authentication`;
  };

  return (
    <div className={cl.wrapper}>
      <div className={cl.bodya}>
        <SVGlogo></SVGlogo>

        <div className={cl.textBox}>
          <h2 className={cl.title}>
            Let’s get this <span>Listing Party</span> started!
          </h2>

          <div className={cl.text}>
            <span>
              Listing Party is currently only available to List Perfectly
              members.
            </span>
            <span>
              <span>Not a List Perfectly member?</span>
              <a href="https://listperfectly.com/">
                <span> Learn more </span>
              </a>
              <span>or</span>
              <a href="https://listperfectly.com/pricing/">
                <span> Sign Up</span>
              </a>
              <span>.</span>
            </span>
          </div>

          <div className={cl.buttons}>
            <LPButton className={cl.loginBtn} onClick={onButtonClick}>
              Login with List Perfectly
            </LPButton>
            {isProd ? null : (
              <>
                <LPButton className={cl.loginBtn2} onClick={onButtonClick2}>
                  APPTEST login
                </LPButton>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={cl.footer}>
        <span>
          © Listing Party - {new Date().getFullYear()}. All Rights Reserved.
        </span>
        <a href="https://www.google.com/">
          <span>Privacy Policy - Terms of Service</span>
        </a>
      </div>
    </div>
  );
};

export default LoginPage;
