import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { SVGlupe, SVGfilter } from '../../../assets/PackageSVG';
import LPButton from '../../ui/button/LPButton';
import Dropdown from '../../ui/dropdowns/Dropdown';
import DropdownWithInput from '../../ui/dropdowns/DropdownWithInput';

import cl from './FilterModules.module.scss';
import { useDispatch } from 'react-redux';
import { setMediator } from '../../../redux/slices/mediatorSlice';

const optionsSumm = {
  optionsListSites: [
    { value: 'Depop', label: 'Depop', valueKey: '1' },
    { value: 'eBay', label: 'eBay', valueKey: '2' },
    { value: 'Etsy', label: 'Etsy', valueKey: '3' },
    { value: 'Facebook', label: 'Facebook', valueKey: '4' },
    { value: 'Grailed', label: 'Grailed', valueKey: '5' },
    { value: 'Instagram', label: 'Instagram', valueKey: '6' },
    { value: 'Kidizen', label: 'Kidizen', valueKey: '7' },
    { value: 'Mercari', label: 'Mercari', valueKey: '8' },
    { value: 'Poshmark', label: 'Poshmark', valueKey: '9' },
    { value: 'Shopify', label: 'Shopify', valueKey: '10' },
    { value: 'Tradesy', label: 'Tradesy', valueKey: '11' },
    { value: 'Amazon', label: 'Amazon', valueKey: '2' },
  ],
  optionsPreferences: [
    { value: 'Virtual', label: 'Virtual', valueKey: '1' },
    { value: 'On-site', label: 'On-site', valueKey: '2' },
  ],
  optionsExperience: [
    { value: 'Less than 1 year', label: 'Less than 1 year', valueKey: '1' },
    { value: '1-2 years', label: '1-2 years', valueKey: '2' },
    { value: '3-5 years', label: '3-5 years', valueKey: '3' },
    { value: '6-14 years', label: '6-15 years', valueKey: '4' },
    { value: '15+ years', label: '15+ years', valueKey: '5' },
  ],
  optionsTypes: [
    { value: 'Assistant', label: 'Assistant', valueKey: '1' },
    { value: 'Consigner', label: 'Consigner', valueKey: '2' },
    { value: 'Seller', label: 'Seller', valueKey: '3' },
    // {value: 'Buyer', label: 'Buyer', valueKey: '4'},
  ],
  optionsSpecialties: [
    {
      value: 'Apparel, Footwear & Accessories',
      label: 'Apparel, Footwear & Accessories',
      valueKey: '1',
    },
    { value: 'Arts & Crafts', label: 'Arts & Crafts', valueKey: '2' },
    { value: 'Auto', label: 'Auto', valueKey: '3' },
    { value: 'Books', label: 'Books', valueKey: '4' },
    { value: 'Collectibles', label: 'Collectibles', valueKey: '5' },
    {
      value: 'Electronics & Media',
      label: 'Electronics & Media',
      valueKey: '6',
    },
    { value: 'Health & Beauty', label: 'Health & Beauty', valueKey: '7' },
    { value: 'Home & Garden', label: 'Home & Garden', valueKey: '8' },
    { value: 'Jewelry & Watches', label: 'Jewelry & Watches', valueKey: '9' },
    { value: 'Movies & TV', label: 'Movies & TV', valueKey: '10' },
    { value: 'Music', label: 'Music', valueKey: '11' },
    { value: 'Pets', label: 'Pets', valueKey: '12' },
    { value: 'Sporting Goods', label: 'Sporting Goods', valueKey: '13' },
    { value: 'Toys', label: 'Toys', valueKey: '14' },
    { value: 'Other', label: 'Other', valueKey: '15' },
  ],
  optionsServices: [
    { value: 'General Assistant', label: 'General Assistant', valueKey: '1' },
    { value: 'Bookkeeping', label: 'Bookkeeping', valueKey: '2' },
    { value: 'Coaching', label: 'Coaching', valueKey: '3' },
    { value: 'Consignment', label: 'Consignment', valueKey: '4' },
    { value: 'Crossposting', label: 'Crossposting', valueKey: '5' },
    {
      value: 'Fulfillment & Shipping',
      label: 'Fulfillment & Shipping',
      valueKey: '6',
    },
    { value: 'Listing creation', label: 'Listing creation', valueKey: '7' },
    { value: 'Listing review', label: 'Listing review', valueKey: '8' },
    { value: 'Photography', label: 'Photography', valueKey: '9' },
    { value: 'Sourcing', label: 'Sourcing', valueKey: '10' },
  ],
};

const DirectoryFilterModule = ({
  menuNotCollapsed,
  setMenuCollapsed,
  ...rest
}) => {
  const [aFirstLoading, setFirstLoading] = useState(true);

  const [aSearch, setSearch] = useState('');
  const [experience, setExperience] = useState('');

  const [type, setType] = useState(null);
  const [listSites, setListSites] = useState(null);
  const [specs, setSpecs] = useState(null);
  const [services, setServices] = useState(null);
  const [locatPrefs, setlocatPrefs] = useState(null);
  const [hasParams, setHasParams] = useState(false);

  //
  //
  //

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    optionsServices,
    optionsSpecialties,
    optionsTypes,
    optionsExperience,
    optionsPreferences,
    optionsListSites,
  } = optionsSumm;

  //
  //
  //

  useEffect(() => {
    if (!aFirstLoading) return;

    //

    const bestFunction = (arrOrString, baseDataArr, show) => {
      const arr = [];

      for (const baseData of baseDataArr) {
        if (show)
          console.log(
            1,
            baseData.value,
            typeof arrOrString,
            typeof arrOrString !== 'object',
            arrOrString,
          );

        if (typeof arrOrString !== 'object') {
          if (baseData.value.toLowerCase() === arrOrString?.toLowerCase())
            arr.push(baseData);
          //return false;
        }

        for (const two of arrOrString) {
          if (show)
            console.log(
              2,
              baseData.value,
              two,
              baseData.value.toLowerCase() === two?.toLowerCase(),
            );
          if (baseData.value.toLowerCase() === two?.toLowerCase())
            arr.push(baseData);
        }
      }

      if (show) console.log(3, JSON.stringify(arr, null, 2));

      return arr;
    };

    //
    let allParams = {};

    for (let [key, value] of searchParams.entries()) {
      if (!value) continue;
      if (key === 'search_everything') {
        if (!allParams[key]) allParams[key] = value;
        else allParams[key] = allParams[key].concat(value);
      } else {
        if (!allParams[key]) allParams[key] = [value];
        else allParams[key] = [...allParams[key], value];
      }
    }

    if (Object.keys(allParams).length > 0) {
      setHasParams(true);
      for (let key in allParams) {
        try {
          if (!allParams[key] || allParams[key].length === 0) continue;
          const value = allParams[key];
          switch (key) {
            case 'search_everything':
              setSearch(value.toString());
              break;
            case 'experience_level':
              setExperience(
                optionsExperience.find(
                  ({ value: exp }) => exp === value.toString(),
                ),
              );
              break;
            case 'types':
              setType(bestFunction(value, optionsTypes));
              break;
            case 'preferred_listing_sites':
              setListSites(bestFunction(value, optionsListSites));
              break;
            case 'listing_specialties':
              setSpecs(bestFunction(value, optionsSpecialties));
              break;
            case 'services':
              setServices(bestFunction(value, optionsServices));
              break;
            case 'location_preferences':
              setlocatPrefs(bestFunction(value, optionsPreferences));
              break;
            default:
              break;
          }
        } catch (e) {
          console.log('[urlParse] err', e.message);
        }
      }

      dispatch(
        setMediator({
          filtersData: allParams,
          metadata: { offset: 0, limit: 25 },
        }),
      );
    } else {
      dispatch(
        setMediator({ filtersData: {}, metadata: { offset: 0, limit: 25 } }),
      );
    }

    //

    setFirstLoading(false);
  }, [searchParams]);

  //
  //
  //

  const getFiltersData = () => {
    const selectedTypes = type && type?.map(({ value }) => value.toLowerCase());
    const selectedListSites =
      listSites && listSites?.map(({ value }) => value.toLowerCase());
    const selectedSpecs =
      specs && specs?.map(({ value }) => value.toLowerCase());
    const selectedServ =
      services && services?.map(({ value }) => value.toLowerCase());
    const selectedLocatPrefs =
      locatPrefs && locatPrefs?.map(({ value }) => value.toLowerCase());

    let dataFromAll = {
      search_everything: aSearch || '',
      experience_level: experience?.value || '',
      types: selectedTypes || undefined,
      preferred_listing_sites: selectedListSites || undefined,
      listing_specialties: selectedSpecs || undefined,
      services: selectedServ || undefined,
      location_preferences: selectedLocatPrefs || undefined,
    };

    dataFromAll = _.pickBy(dataFromAll, function (value, key) {
      return !(value === undefined || value === [] || value === '');
    });
    return dataFromAll;
  };

  const handleApplyFilters = () => {
    const dataFromAll = getFiltersData();

    let dataForQuery = {
      search_everything: dataFromAll.search_everything,
      experience_level: dataFromAll.experience_level,
      types: JSON.stringify(dataFromAll.types),
      preferred_listing_sites: JSON.stringify(
        dataFromAll.preferred_listing_sites,
      ),
      listing_specialties: JSON.stringify(dataFromAll.listing_specialties),
      services: JSON.stringify(dataFromAll.services),
      location_preferences: JSON.stringify(dataFromAll.location_preferences),
    };

    dataForQuery = _.pickBy(dataFromAll, function (value) {
      return !(value === undefined);
    });

    setSearchParams(dataForQuery);

    // console.log('[handleApplyFilters] data:', dataFromAll, dataForQuery);

    dispatch(
      setMediator({
        filtersData: dataFromAll,
        metadata: { offset: 0, limit: 25 },
      }),
    );
  };

  const handleClearFilters = () => {
    setSearch('');
    setExperience('');
    setlocatPrefs([]);
    setListSites([]);
    setServices([]);
    setSpecs([]);
    setType([]);
    navigate('/directory');
    window.location.reload();
  };

  const handleTypesChange = (e) => {
    setType(e);
  };
  const handleExperChange = (e) => {
    setExperience(e);
  };
  const handleSpecsChange = (e) => {
    setSpecs(e);
  };
  const handleListSitesChange = (e) => {
    setListSites(e);
  };
  const handlePrefsChange = (e) => {
    setlocatPrefs(e);
  };
  const handleServChange = (e) => {
    setServices(e);
  };

  return (
    <>
      {menuNotCollapsed ? (
        <div className={cl.wrapper} {...rest}>
          <div className={cl.oneLine}>
            <label htmlFor="search">Search</label>
            <div className={cl.field}>
              <input
                placeholder="Search"
                id="search"
                value={aSearch}
                onChange={({ target }) => setSearch(target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') handleApplyFilters();
                }}
              ></input>
              <SVGlupe className={cl.lupe}></SVGlupe>
            </div>
          </div>
          <div className={cl.oneLine}>
            <label htmlFor="type">
              Who are you looking for?
              {/* <Tooltip>
								<p>Text</p>
							</Tooltip> */}
            </label>
            <Dropdown
              id="type"
              handleChange={handleTypesChange}
              handleEnterKeyPress={handleApplyFilters}
              isMulti={true}
              value={type}
              isClearable={true}
              isSearchable={true}
              options={optionsTypes}
            />
          </div>
          <div className={cl.oneLine}>
            <label htmlFor="experience">
              Experience level
              {/* <Tooltip>
								<p>Text</p>
							</Tooltip> */}
            </label>
            <Dropdown
              id="experience"
              handleChange={handleExperChange}
              handleEnterKeyPress={handleApplyFilters}
              isMulti={false}
              value={experience}
              isClearable={true}
              isSearchable={true}
              options={optionsExperience}
            />
          </div>
          <div className={cl.oneLine}>
            <label htmlFor="listingSites">
              Listing Sites
              {/* <Tooltip>
								<p>Text</p>
							</Tooltip> */}
            </label>
            <DropdownWithInput
              id="listingSites"
              isMulti={true}
              options={optionsListSites}
              handleChange={handleListSitesChange}
              handleEnterKeyPress={handleApplyFilters}
              isClearable={true}
              placeholder="Select or type your own"
              value={listSites}
            />
          </div>
          <div className={cl.oneLine}>
            <label htmlFor="listingSpecialties">
              Listing Specialties
              {/* <Tooltip>
								<p>Text</p>
							</Tooltip> */}
            </label>
            <Dropdown
              id="listingSpecialties"
              handleEnterKeyPress={handleApplyFilters}
              handleChange={handleSpecsChange}
              isMulti={true}
              value={specs}
              isClearable={true}
              isSearchable={true}
              options={optionsSpecialties}
            />
          </div>
          <div className={cl.oneLine}>
            <label htmlFor="services">
              Services
              {/* <Tooltip>
								<p>Text</p>
							</Tooltip> */}
            </label>
            <DropdownWithInput
              id="services"
              handleChange={handleServChange}
              handleEnterKeyPress={handleApplyFilters}
              isMulti={true}
              value={services}
              options={optionsServices}
              isClearable={true}
              placeholder="Select or type your own"
            />
          </div>
          <div className={cl.oneLine}>
            <label htmlFor="listingPreferences">
              Location Preference
              {/* <Tooltip>
								<p>Text</p>
							</Tooltip> */}
            </label>
            <Dropdown
              id="listingPreferences"
              handleChange={handlePrefsChange}
              handleEnterKeyPress={handleApplyFilters}
              isMulti={true}
              value={locatPrefs}
              isClearable={true}
              isSearchable={true}
              options={optionsPreferences}
            />
          </div>
          <div className={cl.inLine}>
            <LPButton onClick={() => handleApplyFilters()}>Apply</LPButton>
            <LPButton onClick={() => handleClearFilters()} line>
              Clear Filters
            </LPButton>
          </div>
        </div>
      ) : (
        <div className={cl.collapsed} {...rest}>
          <div className={cl.item} onClick={() => setMenuCollapsed(true)}>
            <SVGfilter></SVGfilter>
          </div>
        </div>
      )}
    </>
  );
};

export default DirectoryFilterModule;
