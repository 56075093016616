import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  eventsTotal: {},
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEventsTotal: (state, action) => {
      state.eventsTotal = { ...state.eventsTotal, ...action.payload };
    },
  },
});

export const { setEventsTotal } = eventsSlice.actions;
export default eventsSlice.reducer;
