import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import pinnedVector from '../../assets/picture/pinned-vector.png';
import useScreenSize from '../../hooks/useScreenSize';
import useBasicFuncs from '../../hooks/useBasicFuncs';

import {
  SVGlive,
  SVGcircleuser,
  SVGcalendardays,
} from '../../assets/PackageSVG';
import Container from '../../components/Container/Container';
import LPButton from '../../components/ui/button/LPButton';

import cl from './EventsPage.module.scss';
import AddCalendar from './components/AddCalendar';
import { useDispatch } from 'react-redux';
import {
  setShowLink,
  setShowModal,
} from '../../redux/slices/eventTermsModalSlice';
import blueBg from '../../assets/picture/blue.png';
import darkBlueBg from '../../assets/picture/darkBlue.png';
import darkGreenBg from '../../assets/picture/darkGreen.png';
import darkPurpleBg from '../../assets/picture/darkPurple.png';
import lightBlueBg from '../../assets/picture/lightBlue.png';
import lightGreenBg from '../../assets/picture/darkGreen.png';
import lightOrangeBg from '../../assets/picture/lightOrange.png';
import lightPurpleBg from '../../assets/picture/lightPurple.png';
import orangeBg from '../../assets/picture/orange.png';
import pinkBg from '../../assets/picture/pink.png';
import redBg from '../../assets/picture/red.png';
import yellowBg from '../../assets/picture/yellow.png';
import partyUserNoImage from '../../assets/picture/partyUserIcon.png';
import './EventCard.css';

const EventCard = ({
  title,
  date,
  description,
  host,
  hosts,
  image,
  link,
  recording_links,
  live,
  tab,
  id,
  pinned,
  cardType,
  dynamicThumbnail,
}) => {
  const { bdecode } = useBasicFuncs();
  const { isMobile, isCollapsible } = useScreenSize();
  const dispatch = useDispatch();
  //
  //
  //

  const correctDate = moment(date).format('MMMM Do, h:mm a');
  const day = moment(date).format('dddd');
  const recording =
    recording_links && recording_links.length > 0 ? recording_links[0] : null;
  const coloredBackgrounds = [
    blueBg,
    darkBlueBg,
    darkGreenBg,
    darkPurpleBg,
    lightBlueBg,
    lightGreenBg,
    lightOrangeBg,
    lightPurpleBg,
    orangeBg,
    pinkBg,
    redBg,
    yellowBg,
  ];
  const [backgroundImage, setBackgroundImage] = useState('');
  const firstFourItems = hosts?.slice(0, 4);
  const imageLinksArray = firstFourItems?.map((item) => {
    if (item?.photo && item.photo?.key) {
      return item?.photo?.key;
    } else {
      return null;
    }
  });

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * coloredBackgrounds.length);
    return coloredBackgrounds[randomIndex];
  };
  const hostData =
    hosts?.length > 0
      ? hosts.map(({ user_id, username }) => {
          return (
            <div className={cl.usernameContainer}>
              {isMobile ? <SVGcircleuser width={16} height={16} /> : ''}
              <Link key={user_id} to={`/profile/${user_id}`}>
                @{username}
              </Link>
            </div>
          );
        })
      : host;

  const imgClass =
    isCollapsible && !isMobile ? [cl.image, cl.tablet].join(' ') : cl.image;

  const notifyLinkCopied = () => {
    const position = isMobile ? 'top-center' : 'bottom-right';
    toast('Link Copied!', {
      position: position,
      autoClose: 3000,
      pauseOnFocus: false,
      pauseOnHover: false,
    });
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * coloredBackgrounds.length);
    setBackgroundImage(coloredBackgrounds[randomIndex]);
  }, []);

  return (
    <>
      <Container
        flex
        className={[
          cl.cardContainer,
          live && cl.gradientBorder,
          tab === 'upcoming' && pinned && cl.pinned,
        ].join(' ')}
      >
        {/* mobile version */}
        {isMobile || isCollapsible ? (
          <div className={cl.wrapperMobile}>
            {pinned && (
              <div className={cl.pinnedIcon}>
                <img src={pinnedVector} />
              </div>
            )}
            <div className={cl.id}>{id}</div>
            <div className={cl.top}>
              {dynamicThumbnail || !image ? (
                <div
                  className={cl.userImage}
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: '100% 100%',
                  }}
                >
                  {imageLinksArray?.map((link, index) => (
                    <img
                      key={index}
                      src={
                        link
                          ? 'https://s3.amazonaws.com/cdn.listingparty.com/' +
                            link
                          : partyUserNoImage
                      }
                      alt={`Image ${index}`}
                      className={`userImages${
                        imageLinksArray.length
                      } partyUserImage${index + 1} partyUserImage`}
                    />
                  ))}
                </div>
              ) : (
                (cardType === 'upcomingEvent' || cardType === 'pastEvent') && (
                  <div className={cl?.leftNormalImage}>
                    {' '}
                    {/* Add optional chaining for potential undefined 'cl' */}
                    <img className={imgClass} src={image} alt="event imagery" />
                  </div>
                )
              )}

              <div className={cl.right}>
                <div className={cl.title}>
                  <h1>{bdecode(title)}</h1>
                  {isMobile && isCollapsible ? (
                    <div className={cl.username}>
                      <div className={cl.hostData}>{hostData}</div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {!isMobile && isCollapsible ? (
                  <>
                    <div className={cl.username}>
                      <SVGcircleuser width={16} height={16} />
                      {hostData}
                    </div>
                    <div className={cl.date}>
                      <SVGcalendardays fill="#173593" width={16} height={16} />
                      <b>{correctDate}</b>
                    </div>{' '}
                  </>
                ) : null}
              </div>
            </div>
            <div className={cl.middle}>
              {isMobile && isCollapsible ? (
                <>
                  <div className={cl.date}>
                    <SVGcalendardays fill="#173593" width={16} height={16} />
                    <b>{correctDate}</b>
                  </div>{' '}
                </>
              ) : null}
              <div className={cl.description}>{bdecode(description)}</div>
            </div>
            <div className={cl.bottom}>
              {(live && link) || pinned || (tab === 'past' && recording) ? (
                <div className={cl.joinEventCard}>
                  <div className={cl.icon}>
                    {live || pinned ? <SVGlive /> : null}
                  </div>
                  <LPButton
                    onClick={() => {
                      if (live || pinned) {
                        dispatch(setShowModal(true));
                      }
                      if ((live || pinned) && tab === 'upcoming') {
                        let linkToShow = link.includes('https://')
                          ? link
                          : `https://${link}`;
                        dispatch(setShowLink(linkToShow));
                      } else if (recording && tab === 'past') {
                        let linkToShow = recording.includes('https://')
                          ? recording
                          : `https://${recording}`;
                        dispatch(setShowLink(linkToShow));
                      }
                    }}
                  >
                    {live || pinned
                      ? 'Join Party!'
                      : tab === 'past' && 'Watch Party!'}
                  </LPButton>
                </div>
              ) : null}
            </div>
            {!live && !pinned && (
              <div className={cl.addCalendarEventCard}>
                <AddCalendar
                  title={title}
                  description={description}
                  date={date}
                />
              </div>
            )}
          </div>
        ) : (
          // desktop version
          <div className={cl.wrapper}>
            {pinned && (
              <div className={cl.pinnedIcon}>
                <img src={pinnedVector} />
              </div>
            )}

            <div className={cl.id}>{id}</div>
            {dynamicThumbnail || !image ? (
              <div
                className={cl?.left} // Add optional chaining for potential undefined 'cl'
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: '100% 100%',
                }}
              >
                {imageLinksArray?.map((link, index) => (
                  <img
                    key={index}
                    src={
                      link
                        ? 'https://s3.amazonaws.com/cdn.listingparty.com/' +
                          link
                        : partyUserNoImage
                    }
                    alt={`Image ${index}`}
                    className={`userImages${
                      imageLinksArray.length
                    } partyUserImage${index + 1} partyUserImage`}
                  />
                ))}
              </div>
            ) : (
              (cardType === 'upcomingEvent' || cardType === 'pastEvent') && (
                <div className={cl?.leftNormalImage}>
                  {' '}
                  {/* Add optional chaining for potential undefined 'cl' */}
                  <img src={image} alt="event imagery" />
                </div>
              )
            )}

            <div className={cl.right}>
              <div className={cl.top}>
                <div className={cl.leftSide}>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <h2>{bdecode(title)}</h2>
                    {(live && link) ||
                    pinned ||
                    (tab === 'past' && recording) ? (
                      <>{(live || pinned) && <SVGlive />}</>
                    ) : null}
                  </div>

                  <div className={cl.username}>
                    <SVGcircleuser width={16} height={16} />
                    {hostData}
                  </div>
                  <div className={cl.date}>
                    <SVGcalendardays fill="#173593" width={16} height={16} />
                    <b>
                      {day}, {correctDate}
                    </b>
                  </div>
                </div>
              </div>
              <div className={cl.bottom}>
                <p>{bdecode(description)}</p>
                {live || pinned ? (
                  <div className={cl.joinEventCard}>
                    <LPButton
                      onClick={() => {
                        if (live || pinned) {
                          dispatch(setShowModal(true));
                        }
                        if ((live || pinned) && tab === 'upcoming') {
                          let linkToShow = link.includes('https://')
                            ? link
                            : `https://${link}`;
                          dispatch(setShowLink(linkToShow));
                        } else if (recording && tab === 'past') {
                          let linkToShow = recording.includes('https://')
                            ? recording
                            : `https://${recording}`;
                          dispatch(setShowLink(linkToShow));
                        }
                      }}
                    >
                      {live || pinned
                        ? 'Join Party!'
                        : tab === 'past' && 'Watch Party!'}
                    </LPButton>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!live && !pinned && (
                  <AddCalendar
                    title={title}
                    description={description}
                    date={date}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default EventCard;
