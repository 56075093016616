import React, { useEffect, useRef, useState } from 'react';

import styles from './EventSelect.module.scss';
import { SVGarrowDOWN } from '../../../../assets/PackageSVG';
import { list, tabs } from '../../mock/eventSelectItems';

function EventSelect(props) {
  const { title, icon, setTab, setTypes } = props;

  const [showEventItems, setShowEventItems] = useState(false);

  const [selectedTitle, setSelectedTitle] = useState(title);

  const eventSelectRef = useRef(null);

  let eventItems = [];

  if (title === 'Upcoming') {
    eventItems = tabs;
  } else if (title === 'All') {
    eventItems = list;
  }

  const handleChangeShowEventItems = () => {
    setShowEventItems(!showEventItems);
  };

  const handleOutsideClick = (event) => {
    if (
      eventSelectRef.current &&
      !eventSelectRef.current.contains(event.target)
    ) {
      setShowEventItems(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleChangeSelectedTitle = (title) => {
    let transformTitle = title.split(' ')[0];

    if (transformTitle === 'Upcoming' || transformTitle === 'Past') {
      setTab(transformTitle.toLowerCase());
    } else if (transformTitle === 'In-person' || transformTitle === 'Virtual') {
      setTypes([transformTitle.toLowerCase().split('-').join('_')]);
    } else if (transformTitle === 'All') {
      setTypes([]);
    }
    setSelectedTitle(title.split(' ')[0]);
  };

  return (
    <div
      ref={eventSelectRef}
      // className={styles.eventSelect}
      className={`${styles.eventSelect} ${styles.eventSelect__active}`}
      onClick={handleChangeShowEventItems}
    >
      <div className={styles.eventSelectTitleBox}>
        <div className={styles.title}>
          <span className={styles.icon}>{icon}</span>
          <span>{selectedTitle}</span>
          <span>
            <SVGarrowDOWN />
          </span>
        </div>
      </div>
      {showEventItems && (
        <div className={styles.eventItems}>
          {eventItems?.map((item) => (
            <div
              key={item.id}
              onClick={() => handleChangeSelectedTitle(item.eventName)}
              className={
                item.eventName.split(' ')[0] === selectedTitle &&
                styles.activeItem
              }
            >
              {item.eventName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EventSelect;
