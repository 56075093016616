import React from 'react';

import _ from 'lodash';

import Tooltip from '../../../../components/ui/tooltip/Tooltip';
import ChargesInput from './ChargesInput';

import cl from './FormCharges.module.scss';

const FormCharges = ({ setSaveBtnDisabled, charges, setCharges, type }) => {
  const handleAddCharge = (e) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    setCharges((prev) => [
      ...prev,
      { name: '', value: '', type: '', currency: 'usd' },
    ]);
  };
  return (
    <>
      <div>
        <div style={{ display: 'flex' }}>
          <h2>How much do you charge?</h2>
          <Tooltip>
            <p>
              Select as many charges and prices as you need. Partiers will have
              a search and filter option available based on your settings.
            </p>
            <p>
              Listing Party will not handle financial transactions, payment
              methods used are entirely up to you which you can specify in your
              assistant notes if you choose.
            </p>
            <p>
              Listing Party is not responsible for any financial arrangements.
            </p>
          </Tooltip>
        </div>
      </div>

      {charges?.map((charge, index) => (
        <ChargesInput
          key={index}
          index={index}
          priceValue={charge.value}
          servValue={_.unescape(charge.name)}
          typeValue={charge.type}
          charges={charges}
          setCharges={setCharges}
          setSaveBtnDisabled={setSaveBtnDisabled}
          type={type}
        />
      ))}

      <div className={cl.addServBox}>
        <button
          className={[cl.addBox, cl[[type]]].join(' ')}
          onClick={handleAddCharge}
        >
          + Add New Charge
        </button>
      </div>
    </>
  );
};

export default FormCharges;
