import { SVGloader } from '../../assets/PackageSVG';

import cl from './Container.module.scss';

//
//
//

const Container = ({
  className,
  children,
  borderless,
  flex,
  isFetching,
  isLoading,
  hidden,
  title,
  titleColor,
  ...rest
}) => {
  const classes = [cl.wrapper];

  if (!flex) classes.push(cl.static);
  else classes.push(cl.flex);

  if (borderless) classes.push(cl.borderless);
  if (className) classes.push(className);

  //
  //
  //

  return (
    <>
      {hidden ? null : (
        <div className={classes.join(' ')} {...rest}>
          {!isFetching && !isLoading && title ? (
            <h2 className={cl.h2title + ' ' + cl?.[titleColor]}>{title}</h2>
          ) : null}

          {isFetching || isLoading ? (
            <div className={cl.loader}>
              <SVGloader />
              <span>Loading...</span>
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      )}
    </>
  );
};

const ContainerOLD = ({
  className,
  children,
  borderless,
  flex,
  isFetching,
  isLoading,
  hidden,
  title,
  ...rest
}) => {
  const classes = [cl.container];
  if (!flex) classes.push(cl.static);
  else classes.push(cl.flex);

  if (borderless) classes.push(cl.borderless);

  return (
    <>
      {hidden ? null : (
        <div className={classes.join(' ')}>
          <div {...rest} className={[cl.cont, className].join(' ')}>
            {isFetching || isLoading ? (
              <div className={cl.loader}>
                <SVGloader />
                <span>Loading...</span>
              </div>
            ) : (
              <>{children}</>
            )}
          </div>
        </div>
      )}
    </>
  );
};

//
//
//

export default Container;
