import React from 'react';

import styles from './ModalButton.module.scss';

function ModalButton(props) {
  const { children, cb = () => console.log('cb'), feature } = props;

  const classes = `${styles.modal__button} ${
    (feature === 'primary' && styles.primary__modal__button) ||
    (feature === 'light' && styles.light__modal__button) ||
    (feature === 'green' && styles.green__modal__button)
  }`;

  return (
    <button className={classes} onClick={cb}>
      {children}
    </button>
  );
}

export default ModalButton;
