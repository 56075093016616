import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, matchPath } from 'react-router-dom';
import {
  SVGlogout,
  SVGedit,
  SVGprofile,
  SVGparties,
  SVGsettings,
  SVGlistings,
  SVGlistperf,
  SVGgoals,
} from '../../../assets/PackageSVG';

import { mediatorSelector } from '../../../redux/selectors';

import cl from './NavigationModule.module.scss';

//
//
//

const LineLink = ({ to, name, icon, children }) => {
  const location = useLocation();
  const match = matchPath(location.pathname, to);

  return (
    <div className={cl.wrapper2}>
      <div className={[cl.item, match ? cl.active : ''].join(' ')}>
        <Link to={to}>
          <div className={cl.line}>
            {icon}
            <span>{name}</span>
          </div>
        </Link>
      </div>
      {children &&
        children?.map(({ to, name }) => (
          <div className={[cl.child, cl.item].join(' ')} key={to}>
            <Link to={to}>
              <div className={cl.line}>
                {/* {icon} */}
                <span>{name}</span>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

const NavigationModule = ({
  checkOneOf,
  menuNotCollapsed,
  isMobile,
  ...rest
}) => {
  const mediator = useSelector(mediatorSelector);

  const array = [
    ...(isMobile && !checkOneOf
      ? [
          { to: '/listings', name: 'Listings', icon: <SVGlistings /> },
          { onlyLine: true },
        ]
      : []),

    { to: '/profile', name: 'Profile', icon: <SVGprofile /> },
    { to: '/profile/edit', name: 'Edit Profile', icon: <SVGedit /> },
    { to: '/goals', name: 'Goals', icon: <SVGgoals /> },
    // {child: true, to: '/profile/edit#what', name: 'What do you do?'},
    // {child: true, to: '/profile/edit#profile', name: 'Basic Info'},
    // {child: true, to: '/profile/edit#experience', name: 'Experience Level'},
    // {to: '/profile/settings', name: 'Settings', icon: <SVGsettings />},
    // {to: '/profile/testpage', name: 'Test Page', icon: <SVGparties />},
    {
      to: '/listperfectly',
      name: 'List Perfectly',
      icon: <SVGlistperf nopaint="true" />,
    },
    { to: '/logout', name: 'Logout', icon: <SVGlogout /> },
  ];

  return (
    <div
      className={[menuNotCollapsed ? '' : cl.collapsed, cl.wrapper].join(' ')}
      {...rest}
    >
      {array?.map(({ to, name, icon, onlyLine }, index) => {
        const children = mediator?.for === to ? mediator?.arr : false;

        if (onlyLine) return <hr key={index * 123}></hr>;

        return (
          <LineLink key={to} to={to} name={name} icon={icon}>
            {children}
          </LineLink>
        );
      })}
    </div>
  );
};

export default NavigationModule;
