import { SVGcollapse } from '../../assets/PackageSVG';

import useScreenSize from '../../hooks/useScreenSize';

import NavigationModule from './Modules/NavigationModule';
import DirectoryFilterModule from './Modules/DirectoryFilterModule';
import ListingsFilterModule from './Modules/ListingsFilterModule';
import EventsFilterModule from './Modules/EventsFilterModule';

import cl from './Menu.module.scss';

const DesktopMenu = ({ aMenuId, menuNotCollapsed, setMenuCollapsed }) => {
  const { isCollapsible } = useScreenSize();

  const jsstyles_menuWrapper = {
    className: [
      menuNotCollapsed ? '' : cl.menuWrapperCollapsed,
      cl.menuWrapper,
    ].join(' '),
    style: { width: menuNotCollapsed ? 298 - 32 : 80 },
  };

  return (
    <>
      <div {...jsstyles_menuWrapper}>
        <div className={cl.menu}>
          <div
            className={cl.collapseButton}
            onClick={() => setMenuCollapsed(!menuNotCollapsed)}
          >
            <SVGcollapse />
            <span>{menuNotCollapsed ? 'Collapse menu' : 'Expand menu'}</span>
          </div>

          <hr></hr>

          {aMenuId === 1 ? (
            <NavigationModule
              menuNotCollapsed={menuNotCollapsed}
            ></NavigationModule>
          ) : aMenuId === 2 ? (
            <DirectoryFilterModule
              menuNotCollapsed={menuNotCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            ></DirectoryFilterModule>
          ) : aMenuId === 3 ? (
            <ListingsFilterModule
              menuNotCollapsed={menuNotCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            ></ListingsFilterModule>
          ) : aMenuId === 4 ? (
            <EventsFilterModule
              menuNotCollapsed={menuNotCollapsed}
            ></EventsFilterModule>
          ) : null}
        </div>
      </div>
      {isCollapsible && menuNotCollapsed ? (
        <div
          className={cl.greyShadow}
          onClick={() => setMenuCollapsed(false)}
        ></div>
      ) : null}
    </>
  );
};

export default DesktopMenu;
