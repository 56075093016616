import React from 'react';

import _ from 'lodash';

import Tooltip from '../../../../components/ui/tooltip/Tooltip';
import SpecialtiesInput from './SpecialtiesInput';

import cl from './FormSpecialties.module.scss';

const FormSpecialties = ({
  setSaveBtnDisabled,
  setSpecialties,
  specialties,
  type,
}) => {
  const optionsSpecialties = [
    { value: 'All Categories', label: 'All Categories', valueKey: '1' },
    {
      value: 'Apparel, Footwear & Accessories',
      label: 'Apparel, Footwear & Accessories',
      valueKey: '2',
    },
    { value: 'Arts & Crafts', label: 'Arts & Crafts', valueKey: '3' },
    { value: 'Auto', label: 'Auto', valueKey: '4' },
    { value: 'Books', label: 'Books', valueKey: '5' },
    { value: 'Collectibles', label: 'Collectibles', valueKey: '6' },
    {
      value: 'Electronics & Media',
      label: 'Electronics & Media',
      valueKey: '7',
    },
    { value: 'Health & Beauty', label: 'Health & Beauty', valueKey: '8' },
    { value: 'Home & Garden', label: 'Home & Garden', valueKey: '9' },
    { value: 'Jewelry & Watches', label: 'Jewelry & Watches', valueKey: '10' },
    { value: 'Movies & TV', label: 'Movies & TV', valueKey: '11' },
    { value: 'Music', label: 'Music', valueKey: '12' },
    { value: 'Pets', label: 'Pets', valueKey: '13' },
    { value: 'Sporting Goods', label: 'Sporting Goods', valueKey: '14' },
    { value: 'Toys', label: 'Toys', valueKey: '15' },
    { value: 'Other', label: 'Other', valueKey: '16' },
  ];

  const optionsDescriptors = [
    { value: 'Costume', label: 'Costume', valueKey: '1' },
    { value: 'Decor', label: 'Decor', valueKey: '2' },
    { value: 'Emphemera', label: 'Emphemera', valueKey: '3' },
    { value: 'Handmade', label: 'Handmade', valueKey: '4' },
    { value: 'Indoor', label: 'Indoor', valueKey: '5' },
    { value: 'Luxury', label: 'Luxury', valueKey: '6' },
    { value: 'Organization', label: 'Organization', valueKey: '7' },
    { value: 'Outdoor', label: 'Outdoor', valueKey: '8' },
    { value: 'Rockabilly', label: 'Rockabilly', valueKey: '9' },
    { value: 'Streetwear', label: 'Streetwear', valueKey: '10' },
    { value: 'Vintage', label: 'Vintage', valueKey: '11' },
  ];
  const handleAddSpecialty = (e) => {
    e.preventDefault();
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    setSpecialties((prev) => [...prev, { name: ' ', descriptors: [''] }]);
  };
  return (
    <>
      <div className={cl.heading}>
        <div style={{ display: 'flex' }}>
          <h2>What are your preferred listing categories?</h2>
          <Tooltip>
            <p>
              Do you have a favorite niche or category? Are you an expert in a
              specific area? Select your specialty category and add additional
              descriptors. For example, are you proficient in selling vintage
              clothing, choose clothing and add a vintage descriptor. Add as
              many specialties as you would like with up to five descriptors.
            </p>
          </Tooltip>
        </div>
      </div>
      <p>Select all that apply or add your own.</p>

      {specialties.map((specialty, index) => (
        <SpecialtiesInput
          key={index}
          component={specialty}
          index={index}
          specialtyValue={_.unescape(specialty.name)}
          descriptorValue={specialty.descriptors}
          specialties={specialties}
          setSpecialties={setSpecialties}
          setSaveBtnDisabled={setSaveBtnDisabled}
          type={type}
          optionsSpecialties={optionsSpecialties}
          optionsDescriptors={optionsDescriptors}
        />
      ))}

      <div className={cl.addServBox}>
        <button
          className={[cl.addBox, cl[[type]]].join(' ')}
          onClick={handleAddSpecialty}
        >
          + Add New Category
        </button>
      </div>
    </>
  );
};

export default FormSpecialties;
