import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { SVGlupe, SVGfilter } from '../../../assets/PackageSVG';
import LPButton from '../../ui/button/LPButton';

import cl from './FilterModules.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { filtersSelector, metadataSelector } from '../../../redux/selectors';
import { setFilters, setMetadata } from '../../../redux/slices/filtersSlice';

const ListingsFilterModule = ({
  menuNotCollapsed,
  setMenuCollapsed,
  ...rest
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  //
  //
  //
  const filters = useSelector(filtersSelector);
  const metadata = useSelector(metadataSelector);

  const [hasParams, setHasParams] = useState(false);
  const [searchListings, setSearchListings] = useState('');

  //
  //
  //

  useEffect(() => {
    let filtersFromUrl = {};

    for (let [key, value] of searchParams.entries()) {
      if (key === 'id' || key === 'u_id' || key === '133') {
        if (key === 'id') filtersFromUrl.id = value;
        if (key === 'u_id') filtersFromUrl.auth_provider_user_id = value;
        if (key === '133') filtersFromUrl[133] = value;
      }
    }

    if (Object.keys(filtersFromUrl).length > 0) {
      setHasParams(true);
      for (let key in filtersFromUrl) {
        try {
          if (!filtersFromUrl[key] || filtersFromUrl[key].length === 0)
            continue;
          const value = filtersFromUrl[key];
          switch (key) {
            case '133':
              setSearchListings(value.toString());
              break;
            default:
              break;
          }
        } catch (e) {
          console.log('[urlParse] err', e.message);
        }
      }

      dispatch(setFilters(filtersFromUrl));

      dispatch(setMetadata({ offset: 0, limit: 10 }));
    } else {
      dispatch(setFilters({}));

      dispatch(setMetadata({ offset: 0, limit: 10 }));
    }
  }, [searchParams]);

  const handleApplyFilters = () => {
    setSearchParams({
      133: searchListings,
    });

    dispatch(setFilters({ 133: searchListings }));

    dispatch(setMetadata({ offset: 0, limit: 10 }));
  };

  const handleClearFilters = () => {
    setSearchListings('');
    navigate('/listings'); //react router clear query ??

    dispatch(setFilters({}));
    dispatch(setMetadata({ offset: 0, limit: 10 }));
  };

  return (
    <>
      {menuNotCollapsed ? (
        <div className={cl.wrapper} {...rest}>
          <div className={cl.oneLine}>
            <label htmlFor="search">Search in Title</label>
            <div className={cl.field}>
              <input
                placeholder="Search"
                id="search"
                value={searchListings}
                onChange={({ target }) => setSearchListings(target.value)}
              ></input>
              <SVGlupe className={cl.lupe}></SVGlupe>
            </div>
          </div>

          <div className={cl.inLine}>
            <LPButton onClick={() => handleApplyFilters('listings')}>
              Apply
            </LPButton>
            <LPButton onClick={() => handleClearFilters('listings')} line>
              Clear Filters
            </LPButton>
          </div>
        </div>
      ) : (
        <div className={cl.collapsed} {...rest}>
          <div className={cl.item}>
            <SVGfilter></SVGfilter>
          </div>
        </div>
      )}{' '}
    </>
  );
};

export default ListingsFilterModule;
