import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { components } from 'react-select';
import { SVGmagnifyingglassfont } from '../../../../assets/PackageSVG';

import RemoveIcon from '../../../../components/ui/removeIcon/RemoveIcon';
import Dropdown from '../../../../components/ui/dropdowns/Dropdown';
import DropdownWithInput from '../../../../components/ui/dropdowns/DropdownWithInput';

import cl from './SpecialtiesInput.module.scss';

const SpecialtiesInput = ({
  index,
  specialties,
  optionsSpecialties,
  optionsDescriptors,
  specialtyValue,
  descriptorValue,
  setSpecialties,
  setSaveBtnDisabled,
  isClearable,
  type,
  ...rest
}) => {
  const [stateSpecsValue, setStateSpecsValue] = useState('');
  const [stateDescrValue, setStateDescrValue] = useState([]);

  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 5 ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Max limit achieved</div>
        )}
      </components.Menu>
    );
  };

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <SVGmagnifyingglassfont
        width={16}
        height={16}
        style={{ marginLeft: '10px' }}
      />{' '}
      {children}
    </components.Control>
  );

  useEffect(() => {
    let arr = [];
    if (descriptorValue && descriptorValue.length > 0) {
      for (let i = 0; i <= descriptorValue.length; i++) {
        if (descriptorValue[i]) {
          let obj = {};
          obj.value = _.unescape(descriptorValue[i]);
          obj.label = _.unescape(descriptorValue[i]);
          obj.valueKey = i;
          arr[i] = obj;
        }
      }
      setStateDescrValue(arr);
    }
  }, [descriptorValue]);

  useEffect(() => {
    if (specialtyValue) {
      let obj = { label: specialtyValue, value: specialtyValue, key: index };
      setStateSpecsValue(obj);
    }
  }, [specialtyValue]);

  const handleRemoveSpecialty = (e, index) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    if (index === 0 && specialties.length <= 1) {
      setStateSpecsValue('');
      setStateDescrValue([]);
      setSpecialties([{ name: '', descriptors: [] }]);
      return;
    }
    let updatedSpecs = specialties.filter((el, ind) => ind !== index);
    setSpecialties(updatedSpecs);
  };

  const handleDescrChange = (e, index) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    setStateDescrValue(e);
    let updatedSpecs = specialties;
    let updatedDescr = e.map((el) => el.value);
    updatedSpecs[index].descriptors = updatedDescr;
    setSpecialties(updatedSpecs);
  };

  const handleSpecialtyChange = (e) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    let updatedSpecialties = specialties;
    if (!e) {
      updatedSpecialties[index].name = '';
      updatedSpecialties[index].descriptors = [];
      setStateSpecsValue({ label: '', value: '', valueKey: 0 });
      setStateDescrValue([]);
      setSpecialties(updatedSpecialties);
    } else {
      updatedSpecialties[index].name = e.value;
      setStateSpecsValue({ label: e.value, value: e.value, key: index });
      setSpecialties(updatedSpecialties);
    }
  };

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 5;

  return (
    <div className={cl.specialtiesContainer}>
      <div {...rest} className={cl.specialtiesBox}>
        <label htmlFor="specialty" className={cl.specialtiesLabel}>
          <Dropdown
            id="specialty"
            title={type}
            isMulti={false}
            options={optionsSpecialties}
            placeholder="Select Category"
            isClearable={true}
            value={stateSpecsValue}
            handleChange={handleSpecialtyChange}
          />
        </label>
        <label
          htmlFor="specialtyDescriptor"
          className={
            !stateSpecsValue.value || stateSpecsValue.value === ' '
              ? [cl.specialtiesLabel, cl.descriptor, cl.disabled].join(' ')
              : [cl.specialtiesLabel, cl.descriptor].join(' ')
          }
        >
          <DropdownWithInput
            id="specialtyDescriptor"
            isMulti={true}
            isClearable={isClearable}
            options={optionsDescriptors}
            placeholder="Type to add your own"
            components={{ Menu, Control }}
            value={stateDescrValue}
            isValidNewOption={isValidNewOption}
            handleChange={(e) => handleDescrChange(e, index)}
            type={type}
          />
        </label>
        {index > 0 || !index ? (
          <RemoveIcon removeField={handleRemoveSpecialty} index={index} />
        ) : (
          <div className={cl.removeBtnBox}></div>
        )}
      </div>
    </div>
  );
};

export default SpecialtiesInput;
