import { useState, useEffect, useRef, useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import useUsers from '../../hooks/useUsers';
import useBasicFuncs from '../../hooks/useBasicFuncs';

import Loader from '../../components/Container/Loader';
import Container from '../../components/Container/Container';
import Card from './Card';
import cl from './DirectoryPage.module.scss';

import UserSharedWorker from '../../hooks/UserSharedWorker';
import { useDispatch, useSelector } from 'react-redux';
import { mediatorSelector } from '../../redux/selectors';
import { setMediator } from '../../redux/slices/mediatorSlice';

const DirectoryPage = () => {
  const [aCounter, setCounter] = useState(0);
  const [anActualData, setActualData] = useState([]);
  const [hasMore, setHasMore] = useState([]);
  const [directoryData, setDirectoryData] = useState([]);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [total, setTotal] = useState('');

  //
  //
  //

  const { usersList } = useUsers();
  const { bdecode } = useBasicFuncs();
  const [searchParams, setSearchParams] = useSearchParams();

  //
  //
  const dispatch = useDispatch();

  const { filtersData = {}, metadata = { offset: 0, limit: 25 } } =
    useSelector(mediatorSelector);

  const [options] = useState({
    include_badges: true,
  });

  const { isFetching, data } = useQuery(
    ['usersList', filtersData, metadata],
    () => usersList(filtersData, metadata, options),
    { enabled: queryEnabled, initialData: [] },
  );

  const observer = useRef(null);

  const lastListingRef = useCallback(
    (node) => {
      if (isFetching) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const updOffset = metadata.offset + 25;

          dispatch(
            setMediator({
              filtersData: filtersData,
              ...(Object.keys(filtersData).length === 0 && {
                metadata: { offset: updOffset, limit: 25 },
              }),
            }),
          );
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isFetching, hasMore],
  );

  //
  //
  //

  const { worker } = UserSharedWorker();

  useEffect(() => {
    let hasParams = false;
    for (let [key, value] of searchParams.entries()) {
      if (!value) continue;
      if (
        key === 'search_everything' ||
        key === 'experience_level' ||
        key === 'types' ||
        key === 'preferred_listing_sites' ||
        key === 'listing_specialties' ||
        key === 'services' ||
        key === 'location_preferences'
      )
        hasParams = true;
    }
    if (
      !queryEnabled &&
      filtersData &&
      !(Object.keys(filtersData || {}).length === 0 && hasParams)
    )
      setQueryEnabled(true);
  }, [filtersData, searchParams]);

  useEffect(() => {
    if (data && queryEnabled) {
      const { data: cards = [], metadata: { total = null, offset } = {} } =
        data || {};
      if (total || total === 0) setTotal(total);
      setDirectoryData((prev) => {
        return offset === 0 ? cards : [...prev, ...cards];
      });
      setHasMore(cards.length > 0);
    }
  }, [data, queryEnabled]);

  //
  //
  //

  useEffect(() => {
    if (!directoryData) {
      setActualData([]);
      return;
    }

    //
    //
    //

    const coshmare = (far = [], rar = []) => {
      if (!far || far?.length === 0) return true;
      if (typeof rar?.[0] === 'object') rar = rar.map(({ name }) => name);
      for (const one of far)
        if (
          !rar.some(
            (a) => bdecode(a?.toLowerCase()) === bdecode(one?.toLowerCase()),
          )
        )
          return false;
      return true;
    };

    const tryFindFilterMatch = (obj, searchFor) => {
      const str = JSON.stringify(obj).toLowerCase();
      const index = str.indexOf(searchFor?.toLowerCase()) + 1; // if 0 than it's not found
      return index !== 0;
    };

    //
    //
    //

    const filters = filtersData || {};
    const adata = [];
    let letsCalc = 0;

    for (const one of directoryData || []) {
      if (one?.types?.length > 0) {
        if (
          one?.types?.includes('consigner') &&
          !one?.types_metadata?.consigner
        ) {
          if (!one.types_metadata) one.types_metadata = {};
          one.types_metadata.consigner = {
            note: 'no notes',
            experience_level: '',
            listing_specialties: [],
            preferred_listing_sites: [],
            services: [],
            pricing: [],
            location_preferences: [],
          };
          letsCalc++;
        }
        if (one?.types?.includes('seller') && !one?.types_metadata?.seller) {
          if (!one.types_metadata) one.types_metadata = {};
          one.types_metadata.seller = {
            note: 'no notes',
            experience_level: '',
            listing_specialties: [],
            preferred_listing_sites: [],
          };
          letsCalc++;
        }
        if (
          one?.types?.includes('assistant') &&
          !one?.types_metadata?.assistant
        ) {
          if (!one.types_metadata) one.types_metadata = {};
          one.types_metadata.assistant = {
            note: 'no notes',
            experience_level: '',
            listing_specialties: [],
            preferred_listing_sites: [],
            services: [],
            pricing: [],
            location_preferences: [],
          };
          letsCalc++;
        }
      } else if (one?.types?.length === 0) continue;

      if (filters?.types?.length > 0) {
        if (!filters?.types?.includes('consigner')) {
          delete one?.types_metadata?.consigner;
          letsCalc--;
          if (one?.types?.includes('consigner')) {
            let updTypes = one?.types.filter((one) => one !== 'consigner');
            one.types = updTypes;
          }
        }
        if (!filters?.types?.includes('seller')) {
          delete one?.types_metadata?.seller;
          letsCalc--;
          if (one?.types?.includes('seller')) {
            let updTypes = one?.types.filter((one) => one !== 'seller');
            one.types = updTypes;
          }
        }
        if (!filters?.types?.includes('assistant')) {
          delete one?.types_metadata?.assistant;
          letsCalc--;
          if (one?.types?.includes('assistant')) {
            let updTypes = one?.types.filter((one) => one !== 'assistant');
            one.types = updTypes;
          }
        }
      }

      //

      const pref = one?.types_metadata;
      delete one?.types_metadata;
      const seachEverFilterFound = tryFindFilterMatch(
        one,
        filters?.search_everything?.trim(),
      ); //looking for search everything filter only in profile, if found set to true
      one.types_metadata = pref;

      for (const type in one?.types_metadata) {
        const typeProfile = one?.types_metadata?.[type];
        let matchFound = true;

        if (
          filters?.experience_level &&
          typeProfile?.experience_level !== filters?.experience_level
        )
          matchFound = false; //if experience level does not match filters' experience level then set false

        if (matchFound)
          matchFound = coshmare(
            filters?.location_preferences,
            typeProfile?.location_preferences,
          );
        if (matchFound)
          matchFound = coshmare(
            filters?.preferred_listing_sites,
            typeProfile?.preferred_listing_sites,
          );
        if (matchFound)
          matchFound = coshmare(filters?.services, typeProfile?.services);

        if (matchFound)
          matchFound = coshmare(
            filters?.listing_specialties,
            typeProfile?.listing_specialties,
          );

        if (!seachEverFilterFound && matchFound && filters?.search_everything)
          if (!tryFindFilterMatch(typeProfile, filters?.search_everything))
            matchFound = false;

        if (!matchFound) {
          delete one?.types_metadata[type];
          letsCalc--;
          let updTypes = one?.types?.filter((one) => one !== type);
          one.types = updTypes;
        }
      }
      letsCalc = letsCalc + Object.keys(one?.types_metadata || {}).length;
      adata.push(one);
    }

    //
    //
    //

    setActualData(adata);
    setCounter(letsCalc);
  }, [directoryData]);

  return (
    <Loader isFetching={isFetching && metadata && metadata.offset === 0}>
      <Container className={cl.wrapper} flex borderless>
        <div className={cl.header}>
          <h2 className={cl.title}>Find a partier to connect with!</h2>
        </div>
        <div className={cl.body}>
          {anActualData.map((one, index) => {
            const {
              name,
              username,
              location,
              photo,
              types,
              id,
              badges,
              types_metadata = {},
            } = one;

            const toRender = types
              ?.map((key) => {
                if (!types_metadata?.[key]) {
                  return false;
                }
                if (anActualData.length === index + 1) {
                  return (
                    <Card
                      key={username + key}
                      lastListingRef={lastListingRef}
                      {...{
                        name,
                        username,
                        location,
                        photo,
                        id,
                        badges,
                        type: key,
                        typeData: types_metadata?.[key],
                      }}
                    />
                  );
                } else {
                  return (
                    <Card
                      key={username + key}
                      badges={badges}
                      {...{
                        name,
                        username,
                        location,
                        photo,
                        id,
                        type: key,
                        typeData: types_metadata?.[key],
                      }}
                    />
                  );
                }
              })
              .filter((e) => !!e);

            return toRender?.length > 0 ? toRender : null;
          })}
        </div>
        {isFetching && metadata && metadata.offset > 0 && (
          <div className={cl.loadingDiv}>Loading...</div>
        )}
      </Container>
    </Loader>
  );
};

export default DirectoryPage;
