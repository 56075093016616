import React, { useState, useEffect } from 'react';
import Compressor from 'compressorjs';

import useUsers from '../../../../hooks/useUsers';
import LPButton from '../../../../components/ui/button/LPButton';
import Image from '../../../../components/ui/image/Image';
import Tooltip from '../../../../components/ui/tooltip/Tooltip';

import cl from './FormImage.module.scss';
import { useSelector } from 'react-redux';
import { baseAwsUrlSelector } from '../../../../redux/selectors';

const doCompress = (file) => {
  return new Promise((done) => {
    new Compressor(file, {
      width: 300,
      height: 300,
      resize: 'cover',

      // maxWidth: 3200,
      // maxHeight: 3200,
      // minHeight: 320,
      // minWidth: 320,

      //

      mimeType: 'image/jpeg',

      quality: 1,
      checkOrientation: false,

      convertTypes: ['image/jpeg', 'image/png'],
      convertSize: 153600,

      //

      success(result) {
        done(result);
      },
    });
  });
};

const FormImage = ({ formPhotoData, setFormPhotoData }) => {
  const [aSelectedImg, setSelectedImg] = useState();
  const [aSavingStatus, setSavingStatus] = useState('');

  const { usersDeletePhoto, usersCreatePost, usersSaveImgAWS } = useUsers();

  const baseAwsUrl = useSelector(baseAwsUrlSelector);

  //
  //
  //

  useEffect(() => {
    if (!aSavingStatus) return;

    let cancelled = false;

    setTimeout(() => {
      if (cancelled) return false;
      setSavingStatus('');
    }, 4000);

    return () => (cancelled = true);
  }, [aSavingStatus]);

  useEffect(() => {
    if (!aSelectedImg?.name) return;

    const doSave = async () => {
      const { fields, url } = await usersCreatePost({
        key:
          new Date().getTime() +
          aSelectedImg.name.replace(/ /g, '_').toLowerCase(),
        content_type: aSelectedImg.type,
      });

      const formData = new FormData();
      for (const [key, value] of Object.entries(fields))
        formData.append(key, value);
      formData.append('file', aSelectedImg, fields.key);

      //

      await usersSaveImgAWS(formData, url);

      setFormPhotoData({
        photo: {
          key: fields.key,
        },
      });

      setSavingStatus('Saved');
    };

    doSave().catch((e) => {
      setSavingStatus('Error');
      console.error('[ImageSave] err', e.message);
    });
  }, [aSelectedImg]);

  //
  //
  //

  const handleImageUpload = async (e) => {
    const fileObj = e?.target?.files?.[0];
    if (!fileObj) return false;
    setSavingStatus('Saving...');

    const type = fileObj?.type?.split('/')?.[0];
    if (type === 'image') {
      const copressedFile = await doCompress(fileObj);
      setSelectedImg(copressedFile);
    } else {
      setSavingStatus('The file is not an image');
    }

    e.target.value = '';
  };

  const handleImageDelete = async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete your profile image?'
    );
    if (!confirm) return false;

    try {
      setSavingStatus('Deleting...');

      await usersDeletePhoto();

      setFormPhotoData({
        photo: {
          key: '',
        },
      });

      setSavingStatus('Deleted');
    } catch (e) {
      console.error('[ImageDelete] err', e.message);
      if (e?.response?.data?.message === 'Profile photo not found.')
        setSavingStatus('Upload image first.');
    }
  };

  //
  //
  //

  const photo_key = formPhotoData?.photo?.key;

  return (
    <div className={cl.wrapper}>
      <div className={cl.left}>
        <h3>Profile Photo</h3>
        <Tooltip>
          <p>Recommended size for the image: 120 x 120px.</p>
        </Tooltip>
      </div>
      <div className={cl.right}>
        <div className={cl.buttons}>
          <label htmlFor="file" className={cl.imgLabel}>
            <input
              className={cl.imgInput}
              type="file"
              id="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
            Upload
          </label>
          <LPButton onClick={handleImageDelete} line disabled={!photo_key}>
            Delete
          </LPButton>
        </div>
        <div className={cl.imageHandler}>
          <Image
            className={cl.image}
            src={photo_key ? baseAwsUrl + '/' + photo_key : ''}
            alt="profile"
          ></Image>
          {aSavingStatus ? (
            <span className={cl.status}>{aSavingStatus}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormImage;
