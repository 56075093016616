import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mediator: {},
  prevnav: {},
};

const mediatorSlice = createSlice({
  name: 'mediator',
  initialState,
  reducers: {
    setMediator: (state, action) => {
      state.mediator = { ...action.payload };
    },
    setPrevNav: (state, action) => {
      state.prevnav = { ...action.payload };
    },
  },
});

export const { setMediator, setPrevNav } = mediatorSlice.actions;
export default mediatorSlice.reducer;
