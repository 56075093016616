import { SVGinPerson, SVGupcoming } from '../../../assets/PackageSVG';

export const tabs = [
  {
    id: Date.now(),
    eventName: 'Upcoming Events',
  },
  {
    id: Date.now(),
    eventName: 'Past Events',
  },
];

export const list = [
  {
    id: Date.now(),
    eventName: 'In-person Events',
  },
  {
    id: Date.now(),
    eventName: 'Virtual Events',
  },
  {
    id: Date.now(),
    eventName: 'All Events',
  },
];

export const eventTypes = [
  {
    id: Date.now(),
    title: 'Upcoming',
    icon: <SVGupcoming />,
  },
  {
    id: Date.now(),
    title: 'All',
    icon: <SVGinPerson />,
  },
];
