import Container from '../components/Container/Container';

const ComingSoon = () => {
  return (
    <Container>
      <h2>Stay tuned</h2>
      <h4>Coming soon... &#127881;</h4>
    </Container>
  );
};

export default ComingSoon;
