import { useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setChatNotification } from '../redux/slices/chatSlice';

const UserSharedWorker = () => {
  const [worker, setWorker] = useState(null);

  // const [sharedWorkerData, setSharedWorkerData] = useState([]);

  const [sharedDataSingleUser, setSharedDataSingleUser] = useState({});
  const [sharedMessagesActionData, setSharedMessagesActionData] = useState({});
  const dispatch = useDispatch();

  const siteURL = `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    try {
      const workerURL = new URL(`${siteURL}/shared-worker.js`);
      const worker = new SharedWorker(workerURL);

      setWorker(worker);

      worker.port.onmessage = (event) => {
        let sharedData = JSON.parse(event.data);

        if (sharedData.code === 'chats.messages.add') {
          const dateString = sharedData?.data?.created_at;
          const date = moment(dateString);
          const formattedTime = date?.format('h:mm a');
          const formattedDate = date?.format('dddd, MMMM Do YYYY');

          if (sharedData.data) {
            dispatch(setChatNotification(true));
            setSharedDataSingleUser(sharedData.data);
          }
        } else if (sharedData.code === 'chats.messages.actions.add') {
          // console.log(sharedData, 'sharedd data into messages.action.add')

          if (sharedData?.data) {
            setSharedMessagesActionData(sharedData.data);
          }
        }
      };
      worker.port.postMessage({
        code: 'set_token',
        token: localStorage.getItem('lp_custom_access_token'),
      });

      return () => {
        worker.port.close();
      };
    } catch (error) {
      console.error(error);
    }
  }, []);

  return {
    worker,
    sharedDataSingleUser,
    // sharedWorkerData,
    sharedMessagesActionData,
  };
};

export default UserSharedWorker;
