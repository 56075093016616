import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../../components/Container/Container';
import { userDataSelector, userNameSelector } from '../../redux/selectors';
import { setUserData } from '../../redux/slices/userDataSlice';

import cl from './InboxPage.module.scss';

const CircleButton = ({ children }) => {
  return (
    <>
      <div className={cl.circleButton}>{children}</div>
    </>
  );
};

const UserDialogLine = ({
  firstname,
  username,
  unread,
  handleDialogLineClick,
  ...rest
}) => {
  return (
    <div
      className={cl.userDialog}
      username={username}
      onClick={() => handleDialogLineClick(username)}
    >
      <span className={cl.firstname}>{firstname}</span>
      <span className={cl.separator}>-</span>
      <span className={cl.username}>@{username}</span>
    </div>
  );
};

const MessageBox = ({ message, self, ...rest }) => {
  return (
    <div left={self ? 'true' : null} className={cl.message} {...rest}>
      {message}
    </div>
  );
};

const InboxPage = () => {
  const [anActiveDialog, setActiveDialog] = useState();
  const [anInputValue, setInputValue] = useState('');

  const data = {
    dialogs: [
      {
        firstname: 'Vadick',
        username: 'b5414',
        unread: true,
      },
      {
        firstname: 'Patrick',
        username: 'pat',
        unread: false,
      },
      {
        firstname: 'Amanda',
        username: 'mandy',
        unread: true,
      },
    ],
  };

  const dataMessages = [
    { from: 'username', self: true, message: 'asdasdads' },
    { from: 'username 2', self: false, message: 'asdasdads222' },
  ];

  const dispatch = useDispatch();

  const username = useSelector(userNameSelector);
  //
  //
  //

  useEffect(() => {
    if (!anActiveDialog) return;

    setInputValue('');

    dispatch(setUserData({ username: 'myUserName' }));

    // get new data for Active_Dialog [anActiveDialog]
  }, [anActiveDialog]);

  //
  //
  //

  const getUnreadMessagesCount = (messages) =>
    messages?.reduce((sum, a) => sum + +a.unread, 0);
  const unreadMessagesCount = getUnreadMessagesCount(data.dialogs);

  const handleDialogLineClick = (username) => {
    setActiveDialog(username);
  };

  const handleMessageChange = (message) => {
    setInputValue(message);
  };

  //
  //
  //

  return (
    <Container className={cl.wrapper} flex>
      <div className={cl.users}>
        <h2>
          Inbox{' '}
          {unreadMessagesCount > 0 ? `(${unreadMessagesCount} unread)` : ''}
        </h2>
        {data?.dialogs?.length > 0 ? (
          data?.dialogs?.map((a) => (
            <UserDialogLine
              key={a.username}
              {...a}
              handleDialogLineClick={handleDialogLineClick}
            ></UserDialogLine>
          ))
        ) : (
          <>
            <div className={cl.dialogMessage}>No messages yet</div>
          </>
        )}
      </div>
      <div className={cl.messages}>
        <div className={cl.header}>header</div>
        <div className={cl.body}>
          {dataMessages?.length > 0 ? (
            dataMessages?.map((messageObject) => (
              <MessageBox
                key={messageObject.message + messageObject.from}
                {...messageObject}
              ></MessageBox>
            ))
          ) : (
            <>
              <div className={cl.message} alert="true">
                No messages yet
              </div>
            </>
          )}
        </div>
        <div className={cl.input}>
          <input
            value={anInputValue}
            onChange={({ target }) => handleMessageChange(target?.value)}
            placeholder="Your message"
          ></input>
          <CircleButton>{'>'}</CircleButton>
        </div>
      </div>
    </Container>
  );
};

export default InboxPage;
