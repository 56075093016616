import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './CreateGroupModal.module.scss';

import Dropzone, { useDropzone } from 'react-dropzone';

import { faker } from '@faker-js/faker';

import { SVGClose } from '../../../../../../assets/PackageSVG';

import defaultPic from '../../../../../../assets/picture/3 1.png';
import ModalButton from '../../../../../../components/ui/ModalButton/ModalButton';

import defaultInvitePhoto from '../../../../../../assets/picture/JOIN THE LISTING PARTY (12) 1.png';

import { SVGplus } from '../../../../../../assets/PackageSVG';
import useUsers from '../../../../../../hooks/useUsers';
import { useGlobalContext } from '../../../../../../contexts/Context';
import { useParams } from 'react-router-dom';

function CreateGroupModal(props) {
  const {
    handleToggleCreateModalOpen,
    handleToggleEditGroupModal,
    role,
    groupItemName = '',
  } = props;

  const { user_id } = useParams();

  const [filterBy, setFilterBy] = useState({
    id: user_id,
  });

  const [groupName, setGroupName] = useState('');

  const [payload, setPayload] = useState({
    name: groupItemName,
  });

  const { groupsAdd, getUserListChats, groupsUpdate } = useUsers();

  const { dispatch, state } = useGlobalContext();

  const { chatItems } = state;

  const createModalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        createModalRef.current &&
        !createModalRef.current.contains(event.target)
      ) {
        role === 'create' && handleToggleCreateModalOpen();
        role === 'edit' && handleToggleEditGroupModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [createModalRef]);

  const handleChangeGroupName = (e) => {
    if (e.target.value.length > 50) return;
    setGroupName(e.target.value);
  };

  const handleChangeEditName = (e) => {
    if (e.target.value.length > 50) return;

    setPayload({ name: e.target.value });
  };

  const handleGroupAdd = async () => {
    try {
      const response = await groupsAdd(groupName);

      if (response.data) {
        dispatch({
          type: 'CHATITEMS',
          payload: [response.data, ...chatItems],
        });
        handleToggleCreateModalOpen();
      }
    } catch (error) {
      console.error(error);
      handleToggleCreateModalOpen();
    }
  };

  const handleGroupUpdate = async () => {
    try {
      const response = await groupsUpdate(filterBy, payload);

      if (response.data) {
        const id = response.data.id;

        const filteredChatItems = chatItems.filter((item) => item.id !== id);

        const editItem = chatItems.find((item) => item.id === id);

        editItem.name = response.data.name;

        dispatch({
          type: 'CHATITEMS',
          payload: [editItem, ...filteredChatItems],
        });
        handleToggleEditGroupModal();
      }
    } catch (error) {
      console.error(error);
      handleToggleEditGroupModal();
    }
  };

  return (
    <div className={styles.createGroup__overlay}>
      <div ref={createModalRef} className={styles.modal}>
        <div className={styles.modalWrapper}>
          <div
            className={styles.closeBtn}
            onClick={() => {
              role === 'create' && handleToggleCreateModalOpen();
              role === 'edit' && handleToggleEditGroupModal();
            }}
          >
            <SVGClose />
          </div>
          <h1 className={styles.createModalTitle}>
            {role === 'create' && 'Create Group'}
            {role === 'edit' && 'Edit Group'}
          </h1>
          <div
            className={`${styles.createModalBlock} ${styles.createModalGrupNameBlock}`}
          >
            <label className={styles.createModalLabel}>Group Name</label>
            {role === 'create' && (
              <input
                id="groupName"
                type="text"
                value={groupName}
                onChange={handleChangeGroupName}
              />
            )}
            {role === 'edit' && (
              <input
                id="groupName"
                type="text"
                value={payload.name}
                onChange={handleChangeEditName}
              />
            )}
          </div>
          {/* <div className={`${styles.createModalBlock} ${styles.createModalUploadBlock}`}>


                        <label className={styles.createModalLabel}>Group Photo</label>
                        <div className={styles.uploadWrapper}>
                            <div>
                                <img src={defaultPic} alt="" />
                            </div>
                            <div className={styles.uploadBtns}>
                                <div className={styles.attachments} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className={styles.backgroundAttachment}>
                                        <div className={styles.uploadBtns}>
                                            <ModalButton
                                                feature="light"
                                            >Upload photo</ModalButton>
                                        </div>
                                    </div>
                                </div>
                                <ModalButton feature="light">Save</ModalButton>
                            </div>
                        </div>

                        <p>Don’t worry, we’ll use a default photo if you don’t want to upload your own!</p>
                    </div> */}
          {/* <div className={`${styles.createModalBlock} ${styles.createModalMembersBlock}`}>
                        <label className={styles.createModalLabel} htmlFor='members'>Members</label>
                        <input
                            id="members"
                            type="text"
                            placeholder="Type @username to search"
                            value={searchItem}
                            onChange={handleSearchChange}
                            // onFocus={() => setShowUserBlock(true)}
                            onBlur={() => setShowUserBlock(false)}
                        />

                        {(showUserBlock && filteredUsers?.length > 0) && <div className={styles.members__users}>
                            {filteredUsers?.map((item) => (
                                <div>{item.username}</div>
                            ))}
                        </div>}
                    </div> */}
          {/* <div className={styles.createModalLastBlock}>
                        <div className={styles.lastBlockWrapper}>
                            <div className={styles.info}>
                                <div>
                                    <img src={defaultInvitePhoto} alt="" />
                                </div>
                                <div>
                                    <h3>Bob Brown</h3>
                                    <span>@bobsellsshoes</span>
                                </div>
                            </div>

                            <div>

                                <ModalButton feature="light"><span><SVGplus /></span><span>Invite to Group</span></ModalButton>
                            </div>
                        </div>

                    </div> */}
          <ModalButton
            feature="primary"
            cb={() => {
              role === 'create' && handleGroupAdd();
              role === 'edit' && handleGroupUpdate();
            }}
          >
            {role === 'create' && 'Create Group'}
            {role === 'edit' && 'Edit Group'}
          </ModalButton>
        </div>
      </div>
    </div>
  );
}

export default CreateGroupModal;
