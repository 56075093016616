import React, { useState, useEffect, cloneElement } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';

import useUsers from '../../hooks/useUsers';
import useScreenSize from '../../hooks/useScreenSize';
import { useSelector } from 'react-redux';
import { userDataSelector, userTypesSelector } from '../../redux/selectors';

const labelShops = [
  'depop',
  'ebay',
  'etsy',
  'facebook',
  'grailed',
  'instagram',
  'kidizen',
  'mercari',
  'poshmark',
  'shopify',
  'tradesy',
  'amazon',
];

const Wrapper = ({ children }) => {
  const profType = children.props.type;
  const saveBtnDisabled = children.props.saveBtnDisabled;
  const setSaveBtnDisabled = children.props.setSaveBtnDisabled;

  const btnTitle =
    saveBtnDisabled && saveBtnDisabled[profType]
      ? 'Please add informtion to the form first.'
      : 'Save form!';

  //
  //
  //
  const { usersUpdate } = useUsers();
  const { isMobile } = useScreenSize();

  const userData = useSelector(userDataSelector);
  const userTypes = useSelector(userTypesSelector);

  //
  //
  //

  const [locationPref, setLocationPref] = useState([]);
  const [experience, setExperience] = useState('');
  const [listingSites, setListingSites] = useState([]);
  const [charges, setCharges] = useState([
    { name: '', value: '', type: '', currency: 'usd' },
  ]);
  const [services, setServices] = useState([]);
  const [specialties, setSpecialties] = useState([
    { name: '', descriptors: [] },
  ]);
  const [notes, setNotes] = useState('');

  const [siteInput, setSiteInput] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);
  const [id, setId] = useState('');

  //
  //
  //

  useEffect(() => {
    if (userData) {
      setId(userData?.id);
      if (profType === 'assistant' || profType === 'consigner') {
        let data = userData?.types_metadata?.[profType] || {};
        const {
          location_preferences = [],
          experience_level = '',
          preferred_listing_sites = [],
          pricing = [{ name: '', value: '', type: '', currency: 'usd' }],
          services = [],
          listing_specialties = [{ name: '', descriptors: [] }],
          notes = '',
        } = data;
        setLocationPref(location_preferences);
        setExperience(experience_level);
        setListingSites(preferred_listing_sites);
        setCharges(
          pricing?.length
            ? pricing
            : [{ name: '', value: '', type: '', currency: 'usd' }],
        );
        setServices(services);
        setSpecialties(
          listing_specialties?.length
            ? listing_specialties
            : [{ name: '', descriptors: [] }],
        );
        setNotes(_.unescape(notes));
      } else if (profType === 'seller') {
        let sellerData = userData?.types_metadata?.seller || {};
        const {
          experience_level = '',
          preferred_listing_sites = [],
          listing_specialties = [{ name: '', descriptors: [] }],
          notes = '',
        } = sellerData;
        setExperience(experience_level);
        setListingSites(preferred_listing_sites);
        setSpecialties(
          listing_specialties?.length
            ? listing_specialties
            : [{ name: '', descriptors: [] }],
        );
        setNotes(_.unescape(notes));
      }
    }
  }, [userData]);

  useEffect(() => {
    if (listingSites && listingSites.length > 0) {
      let savedCustomSites = listingSites.filter(
        (site) => !labelShops.includes(site),
      );
      let savedShops = listingSites.filter((site) => labelShops.includes(site));
      let customSites = [];
      for (let i = 0; i <= savedCustomSites.length - 1; i++) {
        if (!savedCustomSites[i]) continue;
        customSites[i] = { id: i, site: savedCustomSites[i], status: true };
      }
      setSiteInput(customSites);
      setSelectedShops(savedShops);
    }
  }, [listingSites]);

  const notifySaving = () => {
    const toastPosition = isMobile ? 'top-center' : 'bottom-right';
    toast('Saved!', {
      position: toastPosition,
      autoClose: 1000,
      pauseOnFocus: false,
      pauseOnHover: false,
    });
  };

  const notifyError = (msg = 'Error saving data.') => {
    const toastPosition = isMobile ? 'top-center' : 'bottom-right';
    toast.warn(msg, {
      position: toastPosition,
      autoClose: 5000,
      draggable: true,
      hideProgressBar: true,
    });
  };

  const saveDataHandler = () => {
    let formData = {};

    //remove decode & from specialties
    //specialties
    let currentSpecialties = specialties; //[{}]
    let updatedSpecialties;
    if (currentSpecialties && currentSpecialties.length > 0) {
      updatedSpecialties = currentSpecialties
        .filter(({ name }) => name && name !== ' ')
        .map(({ name, descriptors }) => {
          name = name.replace(/amp;/gi, '');
          descriptors = descriptors.map((descr) => descr.replace(/amp;/gi, ''));
          return { name, descriptors };
        });
    }
    formData.listing_specialties = updatedSpecialties;
    //listingsites
    const customSites = siteInput.map(({ site }) => site);
    const updatedListingSites = [...selectedShops, ...customSites];
    formData.preferred_listing_sites = updatedListingSites;
    //experience
    formData.experience_level = experience;
    //notes
    formData.notes = notes;

    if (profType !== 'seller') {
      //locatPref data
      formData.location_preferences = locationPref;
      //services
      formData.services = services;
      //remove decode & from charges
      //charges
      let currentCharges = charges;
      let updatedCharges;
      if (currentCharges && currentCharges.length > 0) {
        updatedCharges = currentCharges
          .filter(({ name }) => name && name !== ' ')
          .map(({ name, type, currency, value }) => {
            name = name.replace(/amp;/gi, '');
            value = value ? value : 0;
            return { name, type, currency, value };
          });
      }
      formData.pricing = updatedCharges;
    }

    let data = {
      payload: {
        ...{ types_metadata: { [profType]: formData } },
        ...{ types: userTypes },
      },
      id: id,
    };
    usersUpdate(data).then(
      () => {
        setSaveBtnDisabled((prev) => ({ ...prev, [profType]: true }));
        notifySaving();
      },
      (error) => {
        let errMsg = _.get(error, 'response.data.message', undefined);
        if (errMsg && typeof errMsg === 'object') {
          //banned words case
          const bannedWords = errMsg?.words?.join() || null;
          if (bannedWords) {
            let msg = `Prohibited words: [${bannedWords}] detected, please remove and try again`;
            notifyError(msg);
          }
          //another error msg case
          const errorMsg = errMsg?.message || null;
          if (errorMsg) {
            notifyError(errorMsg.replace(/_/gi, ' '));
          }
        } else if (errMsg && typeof errMsg === 'string') {
          notifyError(errMsg.replace(/_/gi, ' '));
        } else if (!errMsg) notifyError();

        console.error('Error saving data', error);
      },
    );
  };

  return (
    <>
      {cloneElement(children, {
        ...{
          locationPref,
          experience,
          listingSites,
          charges,
          services,
          specialties,
          notes,
          setLocationPref,
          setCharges,
          setExperience,
          setListingSites,
          setSpecialties,
          setServices,
          setNotes,
          siteInput,
          setSiteInput,
          selectedShops,
          setSelectedShops,
          btnTitle,
          saveDataHandler,
        },
      })}
    </>
  );
};
export default Wrapper;
