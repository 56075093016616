import React, { useState } from 'react';
import cl from './Toggle.module.scss';

const Toggle = ({ toggleChecked, setToggleChecked, setSaveBtnDisabled }) => {
  let toggleClassName = toggleChecked
    ? [cl.toggleSwitch, cl.active].join(' ')
    : cl.toggleSwitch;
  return (
    <div className={cl.toggleDiv}>
      <div className={cl.toggleText}>
        <span>Show this on your profile page?</span>
      </div>
      <label htmlFor="toggle" className={toggleClassName}>
        <span
          id="toggle"
          className={cl.slider}
          onClick={() => {
            setSaveBtnDisabled((prev) => ({ ...prev, aboutme: false }));
            setToggleChecked((prev) => !prev);
          }}
        ></span>
      </label>
    </div>
  );
};
export default Toggle;
