import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';

import { baseApiUrlSelector, tokenSelector } from '../redux/selectors';
import { setAuth } from '../redux/slices/authSlice';

const useUsers = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const token = useSelector(tokenSelector);

  const baseApiUrl = useSelector(baseApiUrlSelector);

  const [defaultHeaders, setDefaultHeaders] = useState({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  });

  const interceptor = ({ url, ...config }) => {
    return new Promise((resolve, reject) => {
      axios({ ...config, headers: defaultHeaders, url: baseApiUrl + url })
        .then((data) => {
          const token = data?.headers['lp-custom-refreshed-token'];
          dispatch(setAuth({ token, isAuthorized: true }));
          localStorage.lp_custom_access_token = token;
          return resolve(data.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            return (window.location.href = '/logout');
          }
          reject(err);
        });
    });
  };

  const interceptorAWS = ({ url, ...config }) => {
    return new Promise((resolve, reject) => {
      axios({ ...config, url })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  };

  //

  const usersList = (filter_by, metadata, options) => {
    return interceptor({
      method: 'POST',
      url: '/listing-party/users/list',
      data: { filter_by, metadata, options },
    });
  };

  const usersUpdate = (payload) => {
    return interceptor({
      method: 'PUT',
      url: '/listing-party/users/update',
      data: payload,
    });
  };

  const usersSelf = (options) => {
    return interceptor({
      method: 'POST',
      url: '/listing-party/users/self',
      data: { options },
    });
  };

  const usersCreatePost = (data) => {
    return interceptor({
      method: 'POST',
      url: '/listing-party/users/create-presigned-post',
      data: data,
    });
  };

  const usersSaveImgAWS = (data, url) => {
    return interceptorAWS({
      method: 'POST',
      url: url,
      data: data,
    });
  };

  const usersDeletePhoto = () => {
    return interceptor({
      method: 'DELETE',
      url: '/listing-party/users/delete-profile-photo',
      data: {},
    });
  };

  //

  const eventsList = (filter_by, sort_by, options, metadata) => {
    console.log('[events] list');

    return interceptor({
      method: 'POST',
      url: '/listing-party/events/list',
      data: { filter_by, sort_by, options, metadata },
    });
  };

  //

  const listingsList = (filter_by, metadata) => {
    return interceptor({
      method: 'POST',
      url: '/listing-party/listings/list',
      data: { filter_by, metadata },
    });
  };

  //

  const goalsAdd = (data, due_date, status) => {
    return interceptor({
      method: 'POST',
      url: '/listing-party/goals/add',
      data: { data, ...due_date, ...status },
    });
  };

  //

  const goalsUpdate = (filter_by = {}, payload = {}) => {
    return interceptor({
      method: 'PUT',
      url: '/listing-party/goals/update',
      data: { filter_by, payload },
    });
  };

  //

  const goalsList = (filter_by, metadata) => {
    return interceptor({
      method: 'POST',
      url: '/listing-party/goals/list',
      data: { filter_by, metadata },
    });
  };

  //

  const goalsClone = (id) => {
    console.log('[goals] clone');

    return interceptor({
      method: 'POST',
      url: '/listing-party/goals/clone',
      data: { id },
    });
  };

  const dmAdd = (user_id) => {
    console.log('[dmAdd] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/dm/add',
      data: { user_id },
    });
  };

  const getUserListChats = (filter_by, options, metadata) => {
    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/list',
      data: { filter_by, options, metadata },
    });
  };

  const addMessage = (message, chat_id) => {
    console.log('[addMessages] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/messages/add',
      data: { message, chat_id },
    });
  };

  const addMessage2 = (data, options) => {
    console.log('[addMessage2] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/messages/add',
      data: { data, options },
    });
  };

  //

  const editMessage = (filter_by, payload) => {
    console.log('[editMessage] ');

    return interceptor({
      method: 'PUT',
      url: '/listing-party/chats/messages/update',
      data: { filter_by, payload },
    });
  };

  const getMessagesList = (filter_by, metadata, options = {}) => {
    console.log('[getMessagesList] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/messages/list',
      data: { filter_by, metadata, options },
    });
  };

  const getBadgeLists = (options, metadata) => {
    return interceptor({
      method: 'POST',
      url: '/listing-party/users/list',
      data: { options, metadata },
    });
  };

  const messagesActionsAdd = (message_id, action, data) => {
    console.log('[messagesActions Add] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/messages/actions/add',
      data: { message_id, action, data },
    });
  };

  const messagesActionsDelete = (message_id) => {
    console.log('[messagesActions delete] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/messages/actions/add',
      data: { message_id },
    });
  };

  //

  const groupsAdd = (name) => {
    console.log('[groupAdd] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/groups/add',
      data: { name },
    });
  };

  const groupsUpdate = (filter_by, payload) => {
    console.log('[groupsUpdate] ');

    return interceptor({
      method: 'PUT',
      url: '/listing-party/chats/groups/update',
      data: { filter_by, payload },
    });
  };

  const groupUsersAdd = (user_id, chat_id) => {
    console.log('[groupsUpdate] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/groups/users/add',
      data: { user_id, chat_id },
    });
  };

  const groupUsersDelete = (user_id, chat_id) => {
    console.log('[groupUsersDelete] ');

    return interceptor({
      method: 'DELETE',
      url: '/listing-party/chats/groups/users/delete',
      data: { user_id, chat_id },
    });
  };

  const groupUsersList = (filter_by, metadata, options) => {
    console.log('[groupsUpdate] ');

    return interceptor({
      method: 'POST',
      url: '/listing-party/chats/groups/users/list',
      data: { filter_by, metadata, options },
    });
  };

  return {
    //
    usersList,
    usersUpdate,
    usersSelf,
    //
    usersCreatePost,
    usersSaveImgAWS,
    usersDeletePhoto,
    //
    eventsList,
    //
    listingsList,
    //
    goalsAdd,
    goalsList,
    goalsClone,
    goalsUpdate,
    //
    //
    dmAdd,
    getUserListChats,
    getBadgeLists,
    addMessage,
    addMessage2,
    //
    editMessage,
    getMessagesList,
    messagesActionsAdd,
    messagesActionsDelete,
    //
    groupsAdd,
    groupsUpdate,
    groupUsersAdd,
    groupUsersDelete,
    groupUsersList,
  };
};

export default useUsers;
