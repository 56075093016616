import React, { useState, useEffect, createRef } from 'react';

import DatePicker from 'react-datepicker';
import Select, { components } from 'react-select';
import moment from 'moment';

import Dropdown from '../../components/ui/dropdowns/Dropdown';
import LPButton from '../../components/ui/button/LPButton';

import 'react-datepicker/dist/react-datepicker.css';
import cl from './AddGoal.module.scss';

//
//
//

const dateToday = moment().add(1, 'days').format('lll');
const dateNextWeek = moment().add(7, 'days').format('lll');
const dateNextMonth = moment().add(1, 'month').format('lll');
const dateNextYear = moment().add(1, 'year').format('lll');
const dueDatesOptions = [
	{ value: dateToday, label: `Due Tomorrow | ${dateToday}`, valueKey: '1' },
	{ value: dateNextWeek, label: `Due Next Week | ${dateNextWeek}`, valueKey: '2' },
	{ value: dateNextMonth, label: `Due Next Month | ${dateNextMonth}`, valueKey: '3' },
	{ value: dateNextYear, label: `Due Next Year | ${dateNextYear}`, valueKey: '4' },
	{ value: `Custom Due Date`, label: `Custom Due Date`, valueKey: '5' },
];

const DueDateDropdown = ({ dueDateOption, setDueDateOption, startDate, setStartDate }) => {
	const [customDateOpen, setCustomDateOpen] = useState(false);
	//
	//
	// bottom option in goal dut date
	const CustomOption = (props) => {
		const { data, innerRef, innerProps } = props;
		return data.label === 'Custom Due Date' ? (
			<div className={cl.dueDateBtnDiv} ref={innerRef} {...innerProps}>
				<LPButton line>Set Custom Due Date</LPButton>
			</div>
		) : (
			<components.Option {...props} />
		);
	};

	const calendarRef = createRef();

	useEffect(() => {
		if (customDateOpen) {
			calendarRef.current.setOpen(true);
		}
	}, [customDateOpen]);

	const handleDueDateOptionsChange = (e) => {
		if (customDateOpen && e?.label !== 'Custom Due Date') setCustomDateOpen(false);
		if (e?.label === 'Custom Due Date') {
			setCustomDateOpen(true);
		}
		setDueDateOption(e);
	};
	return (
		<div className={cl.dueDateDropdown}>
			<Dropdown
				placeholder='Set Due Date'
				options={dueDatesOptions}
				components={{ Option: CustomOption }}
				value={dueDateOption}
				handleChange={(e) => handleDueDateOptionsChange(e)}
				isClearable={true}
			/>

			{customDateOpen ? (
				<div className={cl.datepicker}>
					<DatePicker
						selected={startDate}
						ref={calendarRef}
						onChange={(date) => {
							setStartDate(date);
						}}
						showTimeSelect
						timeFormat='p'
						timeIntervals={15}
						dateFormat='Pp'
					/>
				</div>
			) : null}
		</div>
	);
};

export default DueDateDropdown;