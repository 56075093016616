import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  chatMetadata: { offset: 0, limit: 10 },
  messagesListFilters: {},
  selectedChatUser: false,
  chatUsers: [],
  chatNotification: false,
  isRoleAllowed: false,
  chatItems: [],
  currentGroupItem: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMetadata: (state, action) => {
      state.chatMetadata = { ...action.payload };
    },
    setMessagesListFilters: (state, action) => {
      state.messagesListFilters = action.payload;
    },
    setSelectedChatUser: (state, action) => {
      state.selectedChatUser = action.payload;
    },
    setChatUsers: (state, action) => {
      state.chatUsers = action.payload;
    },
    userSplicerToUp: (state, action) => {
      const user = state.chatUsers.splice(action.payload, 1)[0];
      state.chatUsers = [user, ...state.chatUsers];
    },
    setChatNotification: (state, action) => {
      state.chatNotification = action.payload;
    },
    setIsRoleAllowed: (state, action) => {
      state.isRoleAllowed = action.payload;
    },
    setChatItems: (state, action) => {
      state.chatItems = action.payload;
    },

    setCurrentGroupItem: (state, action) => {
      state.currentGroupItem = action.payload;
    },
  },
});

export const {
  setChatMetadata,
  setMessagesListFilters,
  setSelectedChatUser,
  setChatUsers,
  userSplicerToUp,
  setChatNotification,
  setIsRoleAllowed,
  setChatItems,
  setCurrentGroupItem,
} = chatSlice.actions;

export default chatSlice.reducer;
