import NavigationModule from '../../components/Menu/Modules/NavigationModule';
import ListingsFilterModule from '../../components/Menu/Modules/ListingsFilterModule';
import Container from '../../components/Container/Container';

const TestPage = () => {
  return (
    <>
      <Container>
        <NavigationModule menuNotCollapsed={true}></NavigationModule>
      </Container>
      <Container flex>
        <NavigationModule menuNotCollapsed={true}></NavigationModule>
      </Container>
      <Container>
        <ListingsFilterModule menuNotCollapsed={true}></ListingsFilterModule>
      </Container>
      <Container flex>
        <ListingsFilterModule menuNotCollapsed={true}></ListingsFilterModule>
      </Container>

      <Container flex>
        <h2>Container Flex</h2>
      </Container>
      <Container borderless>
        <h2>Container Static BS</h2>
      </Container>
      <Container flex borderless>
        <h2>Container Flex BS</h2>
      </Container>
    </>
  );
};

export default TestPage;
