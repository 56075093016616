import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './assets/styles/index.scss';
import './assets/styles/modern.scss';

import Context from './contexts/Context';

import { Provider } from 'react-redux';
import { store } from './redux/store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(document.getElementById('root'));

const CombinedContext = ({ children }) => (
  <Provider store={store}>
    <Context>{children}</Context>
  </Provider>
);

root.render(
  <QueryClientProvider client={queryClient}>
    <CombinedContext>
      <App />
    </CombinedContext>
  </QueryClientProvider>
);

serviceWorkerRegistration.register();
