import React, { useState, useEffect, useRef, useCallback, memo } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import moment from 'moment';

import InfiniteScroll from 'react-infinite-scroll-component';

import { useGlobalContext } from '../../../../contexts/Context';

import useUsers from '../../../../hooks/useUsers';

import profilePic from '../../../../assets/picture/profile.jpg';
import closeBtn from '../../../../assets/picture/Vector.svg';

import { sortedData } from '../.../../../../../utils/sortedData';

import styles from './ChatMessages.module.scss';

import { SendForm } from './components/SendForm/SendForm';
//
import UserSharedWorker from '../../../../hooks/UserSharedWorker';

import { unescape } from 'lodash';
import _ from 'lodash';
import { removeTags } from '../../../../utils/removeTags';
import {
  SVGgroup,
  SVGmessagesfont,
  SVGloader,
} from '../../../../assets/PackageSVG';
import MessagesText from './components/MessagesText/MessagesText';

import defaultPic from '../../../../assets/picture/3 1.png';
import ModalButton from '../../../../components/ui/ModalButton/ModalButton';

import LeaveGroupModal from './components/LeaveGroupModal/LeaveGroupModal';
import GroupMembersModal from './components/GroupMembersModal/GroupMembersModal';
import CreateGroupModal from './components/CreateGroupModal/CreateGroupModal';

const ChatMessages = () => {
  const { state, dispatch } = useGlobalContext();

  const { user_id } = useParams();

  const [isNotMessages, setIsNotMessages] = useState(true);

  const [filterBy, setFilterBy] = useState({
    chat_id: user_id,
  });

  const {
    messagesListFilters,
    chatMetadata,
    selectedChatUser,
    chatItems,
    currentGroupItem,
  } = state;

  const [loading, setLoading] = useState(true);

  const [userInfoData, setUserInfoData] = useState(null);

  const [messageText, setMessageText] = useState('');

  const [allMessages, setAllMessages] = useState([]);

  const [hasMore, setHasMore] = useState(true);

  const messagesEndRef = useRef(null);

  const [userAdata, setUserAdata] = useState({});

  const [isOpenGroupSettings, setIsOpenGroupSettings] = useState(false);

  const [isOpenLeaveGroupModal, setIsOpenLeaveGroupModal] = useState(false);

  const [isOpenGroupMembersModal, setIsOpenGroupMembersModal] = useState(false);

  const [isOpenEditGroupModal, setIsOpenEditGroupModal] = useState(false);

  //

  const { addMessage, addMessage2, getMessagesList, usersSelf } = useUsers();

  const location = useLocation();
  //
  //
  const { sharedDataSingleUser } = UserSharedWorker();

  const groupSettingsRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        groupSettingsRef.current &&
        !groupSettingsRef.current.contains(event.target)
      ) {
        setIsOpenGroupSettings(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [groupSettingsRef]);

  useEffect(() => {
    setFilterBy({ chat_id: user_id });
  }, []);

  useEffect(() => {
    setLoading(true);

    if (user_id) {
      setIsNotMessages(false);

      getMessages();
    } else {
      setIsNotMessages(true);
    }
  }, [user_id]);

  function getMessages() {
    setHasMore(true);
    setMessageText('');

    dispatch({
      type: 'CHATMETADATA',
      payload: { offset: chatMetadata.offset + 10, limit: 10 },
    });

    usersSelf()
      .then((data) => {
        if (data.data) {
          const userAdata = data.data;

          setUserAdata(userAdata);

          setUserInfoData(data.data);
          const newFilter = Object.keys(messagesListFilters)?.length
            ? messagesListFilters
            : filterBy;

          getMessagesList(newFilter, chatMetadata, { include_user: true })
            .then((data) => {
              if (data?.data) {
                const { chat_id } = data.filter_by;

                setLoading(false);

                const sortedMessages = sortedData(data.data, userAdata);

                setAllMessages(sortedMessages);

                dispatch({
                  type: 'MESSAGESLIST',
                  payload: {},
                });
              }
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function sendMessage(event) {
    event.preventDefault();

    if (removeTags(messageText) === '' || !removeTags(messageText)?.trim())
      return;
    if (!user_id) return;

    try {
      // addMessage(messageText, user_id).then((data) => {
      const options = {
        include_user: true,
        include_chat: true,
      };
      const data = {
        message: messageText,
        chat_id: user_id,
      };
      addMessage2(data, options)
        .then((data) => {
          if (data?.data) {
            console.log(data.data, 'yes data data');
            const message = data.data.message;
            const dateString = data.data.created_at;
            const key = data.data.id;
            const date = moment(dateString);
            const formattedTime = date.format('h:mm A');
            const formattedDate = date.format('dddd, MMMM Do YYYY');

            //

            const { chat, user } = data.data;

            setAllMessages([
              ...allMessages,
              ...[
                {
                  message,
                  created_at: dateString,
                  created_time: formattedTime,
                  created_date: formattedDate,
                  data: user,
                  chat,
                  key,
                  inComing: false,
                },
              ],
            ]);

            chatItems?.forEach((item) => {
              if (item.id === data.data.chat_id) {
                if (item.id !== userAdata.id) {
                  item.message = { message, actions: 'seen' };
                }
              }
            });

            const userIndex = chatItems.findIndex(
              (chatItem) => chatItem.id === data.data.chat_id
            );

            if (userIndex !== -1) {
              const user = chatItems.splice(userIndex, 1)[0];

              dispatch({
                type: 'CHATITEMS',
                payload: [user, ...chatItems],
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });

      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      setMessageText('');
    } catch (error) {
      console.error(error);
    }
  }

  const uniqueDates = [
    ...new Set(
      allMessages
        .sort((a, b) => {
          if (a.created_at < b.created_at) {
            return -1;
          }
          if (a.created_at > b.created_at) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        })
        .reverse()
        .map((item) => item.created_date)
    ),
  ];

  const { name, username } = location?.state?.chatUser || {
    name: '',
    username: '',
  };
  const { last_name, first_name } = name;

  const { groupItem, type } = location.state || {};

  const title = location?.state?.title;
  const listingId = location?.state?.listingId;

  const photo = location?.state?.chatUser?.photo;
  const USER_PHOTO = 'https://s3.us-east-1.amazonaws.com/cdn.listingparty.com/';
  const INTERLOCUTOR_PHOTO = photo?.key ? USER_PHOTO + photo?.key : profilePic;

  const GROUP_PHOTO = defaultPic;

  function loadFunc() {
    getMessagesList({ chat_id: user_id }, chatMetadata, { include_user: true })
      .then((data) => {
        if (data?.data?.length) {
          let updOffset = chatMetadata.offset + 10;

          dispatch({
            type: 'CHATMETADATA',
            payload: { offset: updOffset, limit: 10 },
          });

          const sortedMessages = sortedData(data.data, userInfoData);

          setAllMessages([...allMessages, ...sortedMessages]);
        } else {
          setHasMore(false);
        }
      })
      .catch((error) => console.error(error));
  }

  const handleChangeResponsiveView = () => {
    dispatch({
      type: 'SELECTEDCHATUSER',
      payload: false,
    });
    const rightBox = document.querySelector('.rightBox');
    const leftBox = document.querySelector('.leftBox');
    rightBox.style.display = 'none';
    leftBox.style.display = 'block';
  };

  useEffect(() => {
    const rightBox = document.querySelector('.rightBox');
    const leftBox = document.querySelector('.leftBox');

    if (window.innerWidth < 768) {
      dispatch({
        type: 'SELECTEDCHATUSER',
        payload: false,
      });
      if (!selectedChatUser) {
        rightBox.style.display = 'none';
      } else {
        rightBox.style.display = 'block';
      }
    } else {
      rightBox.style.display = 'block';
      leftBox.style.display = 'block';
    }
    return () => {
      rightBox.style.display = 'block';
      leftBox.style.display = 'block';
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (sharedDataSingleUser.chat_id === user_id) {
      const message = sharedDataSingleUser.message;

      const dateString = sharedDataSingleUser.created_at;
      const key = sharedDataSingleUser.id;
      const date = moment(dateString);
      const formattedTime = date.format('h:mm A');
      const formattedDate = date.format('dddd, MMMM Do YYYY');
      const userData = sharedDataSingleUser.user;
      const chat = sharedDataSingleUser.chat;

      let temp;
      temp = {
        message,
        created_at: dateString,
        created_time: formattedTime,
        created_date: formattedDate,
        data: userData,
        chat,
        key,
        inComing: true,
      };

      setAllMessages([...allMessages, temp]);
    }
  }, [sharedDataSingleUser]);

  useEffect(() => {
    const rightBox = document.querySelector('.rightBox');
    const leftBox = document.querySelector('.leftBox');

    if (window.innerWidth <= 768 && location.pathname !== '/chat') {
      leftBox.style.display = 'none';
      rightBox.style.display = 'block';
    }

    return () => {
      leftBox.style.display = 'block';
      rightBox.style.display = 'block';
    };
  }, []);

  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(event.target.href, '_blank');
  };

  const handleToggleGroupSettings = () => {
    setIsOpenGroupSettings(!isOpenGroupSettings);
  };

  const handleToggleLeaveGroupModal = () => {
    setIsOpenLeaveGroupModal(!isOpenLeaveGroupModal);
  };

  const handleToggleGroupMembersModal = () => {
    setIsOpenGroupMembersModal(!isOpenGroupMembersModal);
  };

  const handleToggleEditGroupModal = () => {
    setIsOpenEditGroupModal(!isOpenEditGroupModal);
  };

  return !isNotMessages ? (
    <>
      <div className={styles.chatMessages}>
        {!loading ? (
          <>
            <div
              style={{
                alignItems: location?.state?.type === 'group' && 'center',
              }}
              className={styles.chatUserInfo}
            >
              <div className={styles.chatUserName}>
                {location?.state?.type === 'dm' ? (
                  <img src={INTERLOCUTOR_PHOTO} alt="" />
                ) : (
                  <img src={GROUP_PHOTO} alt="" />
                )}
                <div>
                  {location?.state && location?.state?.type === 'dm' ? (
                    <h2>
                      {first_name} {last_name}
                    </h2>
                  ) : (
                    <h2>{groupItem?.name}</h2>
                  )}
                  {location?.state && location?.state?.type === 'dm' ? (
                    <span>@{username}</span>
                  ) : (
                    <span
                      className={styles.groupMembers}
                      onClick={handleToggleGroupMembersModal}
                    >
                      {/* {currentGroupItem?.users?.length || 1} members */}
                    </span>
                  )}
                </div>
              </div>

              {location?.state?.type !== 'dm' && (
                <div className={styles.groupSettings__block}>
                  <ModalButton feature="light" cb={handleToggleGroupSettings}>
                    <span>
                      <SVGgroup />
                    </span>
                    <span>Group Settings</span>
                  </ModalButton>

                  {isOpenGroupSettings && (
                    <ul
                      ref={groupSettingsRef}
                      className={styles.groupSettings__items}
                    >
                      <li
                      // onClick={handleToggleEditGroupModal}
                      >
                        <span>Edit Group Info</span>
                      </li>
                      <li onClick={handleToggleGroupMembersModal}>
                        <span>View/Add Members</span>
                      </li>
                      <li>
                        <span>Mute Chat</span>
                      </li>
                      <li onClick={handleToggleLeaveGroupModal}>
                        <span>Leave Group</span>
                      </li>
                    </ul>
                  )}
                </div>
              )}

              <div
                onClick={handleChangeResponsiveView}
                className={styles.closeBtn}
              >
                <img src={closeBtn} alt="" />
              </div>
            </div>

            <div className={styles.messagesContainer}>
              <div
                style={{
                  overflow: allMessages?.length < 10 ? 'hidden' : 'auto',
                }}
                className={styles.messages}
              >
                {!allMessages?.length && !loading && (
                  <span className={styles.noMessages}>
                    There are no messages yet :(
                  </span>
                )}
                <div
                  id="scrollableDiv"
                  ref={messagesEndRef}
                  style={{
                    height: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    padding: '0 20px 0 0',
                  }}
                >
                  <InfiniteScroll
                    dataLength={allMessages?.length}
                    next={loadFunc}
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      overflow: 'hidden',
                    }}
                    inverse={true}
                    hasMore={hasMore}
                    loader={
                      allMessages?.length >= 10 && (
                        <div className={styles.infiniteLoader}>
                          <SVGloader />
                          <span>Loading...</span>
                        </div>
                      )
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    {uniqueDates?.map((uniqDate) => (
                      <div>
                        <span className={styles.messagesTitle}>{uniqDate}</span>
                        {allMessages
                          .filter((item) => item?.created_date === uniqDate)
                          .sort((a, b) => {
                            if (
                              new Date(a.created_at) < new Date(b.created_at)
                            ) {
                              return -1;
                            }
                            if (
                              new Date(a.created_at) > new Date(b.created_at)
                            ) {
                              return 1;
                            }
                            if (a.id < b.id) {
                              return -1;
                            }
                            if (a.id > b.id) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item, i) => (
                            <div
                              className={
                                item.inComing
                                  ? styles.message
                                  : styles.messageReverse
                              }
                              key={item.id}
                            >
                              <div className={styles.messageAvatar}>
                                <img
                                  // src={
                                  //   item.data
                                  //     ? USER_PHOTO + item?.data?.photo?.key
                                  //     : !item.inComing
                                  //       ? item?.data?.photo?.key
                                  //         ? USER_PHOTO + item?.data?.photo?.key
                                  //         : profilePic
                                  //       : INTERLOCUTOR_PHOTO
                                  // }
                                  src={
                                    (item?.data?.photo?.key
                                      ? USER_PHOTO + item?.data?.photo?.key
                                      : profilePic) || INTERLOCUTOR_PHOTO
                                  }
                                  alt=""
                                />
                              </div>
                              <MessagesText item={item} />
                            </div>
                          ))}
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
              <div className={styles.formContainer}>
                <SendForm
                  messageText={messageText}
                  setMessageText={setMessageText}
                  sendMessage={sendMessage}
                  title={title}
                  listingId={listingId}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.loader}>
            <SVGloader />
            <span>Loading...</span>
          </div>
        )}
      </div>
      {isOpenLeaveGroupModal && (
        <LeaveGroupModal
          handleToggleLeaveGroupModal={handleToggleLeaveGroupModal}
        />
      )}
      {isOpenGroupMembersModal && (
        <GroupMembersModal
          handleToggleGroupMembersModal={handleToggleGroupMembersModal}
          userAdata={userAdata}
        />
      )}
      {isOpenEditGroupModal && (
        <CreateGroupModal
          role="edit"
          handleToggleEditGroupModal={handleToggleEditGroupModal}
          groupItemName={groupItem.name}
        />
      )}
    </>
  ) : (
    <div className={styles.welcomeContainer}>
      <SVGmessagesfont className={styles.welcomeIcon} />
      <div className={styles.welcomeTitle}>
        Select a chat to start messaging
      </div>
    </div>
  );
};

export default memo(ChatMessages);
