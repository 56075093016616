import { Box } from '../../components/ui/Box';

import { useDebugValue, useEffect } from 'react';

import styles from './ChatPage.module.scss';

import ChatUsers from './components/ChatUsers/ChatUsers';

import ChatMessages from './components/ChatMessages/ChatMessages';

import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../../redux/selectors';
import {
  setChatNotification,
  setIsRoleAllowed,
} from '../../redux/slices/chatSlice';
import { useGlobalContext } from '../../contexts/Context';

export const ChatPage = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  useEffect(() => {
    dispatch(setChatNotification(false));

    return () => {
      dispatch(setChatNotification(false));
    };
  }, []);

  useEffect(() => {
    const decodedToken = jwt_decode(token);
    const roles = decodedToken?.data?.user?.roles;

    const is_role_allowed = roles.some((role) => {
      return role.toLowerCase() === 'listing_party_chats_administrator';
    });

    dispatch(setIsRoleAllowed(is_role_allowed));
  }, []);

  const { state } = useGlobalContext();

  return (
    <div className={styles.chatContainer}>
      <div className={styles.innerContainer}>
        <Box direction="topRight">
          <ChatUsers />
        </Box>
        <Box direction="">
          <ChatMessages />
        </Box>
      </div>
    </div>
  );
};
