import cl from './Image.module.scss';
import profile_picture from '../../../assets/picture/profile.jpg';

const Image = ({ className, src, alt, backupImage, ...rest }) => {
  const wrapperStyle = {
    backgroundImage: `url(${backupImage || profile_picture})`,
    backgroundSize: '100% 100%',
  };

  //

  return (
    <div
      {...rest}
      style={wrapperStyle}
      className={[cl.wrapper, className].join(' ')}
    >
      <>
        <img
          src={src || profile_picture}
          alt={alt}
          loading="lazy"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = backupImage || profile_picture;
          }}
        ></img>
      </>
    </div>
  );
};

export default Image;
