import React, { useState, useEffect } from 'react';

import { 
  SVGanalyticsArt,
  SVGchevrondown,
  SVGtriangleexclamation,
  SVGcirclestar,
  SVGcirclecheck,
 } from '../../assets/PackageSVG';
import { useQuery } from '@tanstack/react-query';
import useUsers from '../../hooks/useUsers';

import AddGoal from './AddGoal';
import GoalsContainer from './GoalsContainer';
import Container from '../../components/Container/Container';
import LPButton from '../../components/ui/button/LPButton';

import cl from './Goals.module.scss';

import UserSharedWorker from '../../hooks/UserSharedWorker';

const quotes = [
  {
    quote: '“A year from now you may wish you had started anyway.”',
    author: 'Karen Lamb',
    id: 1,
  },
  {
    quote: '“Someday is not a day of the week.”',
    author: 'Janet Dailey',
    id: 2,
  },
  {
    quote:
      '“Stop waiting. The time will never be perfect. Things will always pop up. The best time to start is always now.”',
    author: 'Anonymous',
    id: 3,
  },
  { quote: '“One day or day one. You decide.”', author: 'Anonymous', id: 4 },
  {
    quote:
      '“You don’t have to be great at something to start but you have to start to be great at something.”',
    author: 'Zig Ziglar',
    id: 5,
  },
  {
    quote: '“The expert in anything was once a beginner.”',
    author: 'Helen Hayes',
    id: 6,
  },
  {
    quote: '“The secret of getting ahead is getting started.”',
    author: 'Mark Twain',
    id: 7,
  },
  {
    quote:
      '“Just believe in yourself. Even if you don’t, pretend that you do and at some point, you will."',
    author: 'Venus Williams',
    id: 8,
  },
  {
    quote: '“Success is the sum of small efforts repeated day in and day out.”',
    author: 'Robert Collier',
    id: 9,
  },
  {
    quote:
      '“It does not matter how slowly you go, so long as you do not stop.”',
    author: 'Confucius',
    id: 10,
  },
  { quote: '“Impossible is just an opinion.”', author: 'Paulo Coelho', id: 11 },
  {
    quote: '“I didn’t get there by wishing for it, but by working for it.”',
    author: 'Esteé Lauder',
    id: 12,
  },
  {
    quote: '“I find that the harder I work, the more luck I seem to have.”',
    author: 'Thomas Jefferson',
    id: 13,
  },
  {
    quote:
      '“The only place where success comes before work is in the dictionary.”',
    author: 'Vidal Sassoon',
    id: 14,
  },
];
const randomNumber = Math.floor(Math.random() * quotes.length);

const GoalsPage = () => {
  /* const [goals, setGoals] = useState([]);
	const [metadata, setMetadata] = useState({offset: 0, limit: 10});*/
  const [copyGoal, setCopyGoal] = useState({});

  const [showAddGoal, setShowAddGoal] = useState(false);
  const [showTab, setShowTab] = useState('upcoming');

  const [username, setUsername] = useState('');

  //

  const { usersSelf } = useUsers();
  //const {isFetching, error, data, refetch} = useQuery(['goalsList'], ()=>goalsList(filters, metadata));
  const { data: userData } = useQuery(['usersSelf'], () => usersSelf());

  const quote = quotes[randomNumber].quote;
  const author = quotes[randomNumber].author;

  const { worker } = UserSharedWorker();

  useEffect(() => {
    if (userData && userData.data) {
      setUsername(userData?.data?.username);
    }
  }, [userData]);

  useEffect(() => {
    if (Object.keys(copyGoal).length > 0) {
      setShowAddGoal(true);
    }
  }, [copyGoal]);

  /* useEffect(()=>{
		if(data && data.data){
			const filteredData = data.data.filter(({status})=>{
				return status !== 'deleted';
			});
			setGoals(filteredData);
		}
	}, [data]); */

  return (
    <Container flex borderless className={cl.mainWrapper}>
      <div className={[cl.top, showAddGoal ? cl.active : null].join(' ')}>
        <h1>Let’s work on your goals, {username}!</h1>
      </div>
      <div className={cl.quote}>
        <span>
          <em>{quote}</em>
        </span>
        <div>{author}</div>
      </div>
      <div className={cl.stats}>
        <div className={cl.left}>
          <div className={cl.top}>
            <h1>Analytics is coming soon!</h1>
          </div>
          {/* <div className={cl.bottom}>
							<div>25</div>
							<div>
								<div>4 overdue</div>
								<div>20 upcoming</div>
							</div>
						</div> */}
        </div>
        <div className={cl.right}>
          <SVGanalyticsArt />
        </div>
      </div>
      <div className={cl.addGoalBtn}>
        {showAddGoal ? null : (
          <LPButton
            line
            onClick={() => {
              setShowAddGoal((prev) => !prev);
            }}
          >
            + Add Goal
          </LPButton>
        )}
      </div>
      <AddGoal
        showAddGoal={showAddGoal}
        setShowAddGoal={setShowAddGoal}
        copyGoal={copyGoal}
      />
      <div className={cl.tabs}>
        <button
          className={[cl.blue, showTab === 'upcoming' && cl.active].join(' ')}
          onClick={() => {
            setShowTab('upcoming');
          }}
        >
          {/* <SVGtriangleexclamation fill="#ffffff" width={17} height={17} /> */}
          Upcoming
          {/* <SVGchevrondown width={17} height={17} /> */}
        </button>
        <button
          className={[cl.pink, showTab === 'overdue' && cl.active].join(' ')}
          onClick={() => {
            setShowTab('overdue');
          }}
        >
          {/* <SVGcirclestar width={17} height={17}/> */}
          Overdue
          {/* <SVGchevrondown width={17} height={17}/> */}
        </button>
        <button
          className={[cl.green, showTab === 'completed' && cl.active].join(' ')}
          onClick={() => {
            setShowTab('completed');
          }}
        >
          {/* <SVGcirclecheck width={17} height={17}/> */}
          Completed
          {/* <SVGchevrondown width={17} height={17}/> */}
        </button>
      </div>
      <GoalsContainer key={showTab} tab={showTab} setCopyGoal={setCopyGoal} />
      {/* {['overdue', 'upcoming', 'completed'].map((one)=>{
				let show = one === 'overdue' ? showOverdue : one === 'upcoming' ? showUpcoming : one === 'completed' && showCompleted;
				return <GoalsContainer key={one} goals={goals} setGoals={setGoals} tab={one} toShow={show} isFetching={isFetching} />;
			})} */}
    </Container>
  );
};

export default GoalsPage;
