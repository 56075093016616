import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  blockState: {
    ban: localStorage.ban === 'true',
    noUsername: localStorage.noUsername === 'true',
  },
};

const blockStateSlice = createSlice({
  name: 'block',
  initialState,
  reducers: {
    setBlockState: (state, action) => {
      localStorage.ban = action.payload?.ban;
      localStorage.noUsername = action.payload?.noUsername;
      state.blockState = action.payload;
    },
  },
});

export const { setBlockState } = blockStateSlice.actions;

export default blockStateSlice.reducer;
