import React, { useEffect, useMemo, useRef } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styles from './SendForm.module.scss';

import btnSend from '../../../../../../assets/picture/btnSend.svg';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

import Quill from 'quill';

import AutoLinks from 'quill-auto-links';
//
import Dropzone, { useDropzone } from 'react-dropzone';

import { SVGdrop, SVGfaceSmile } from '../../../../../../assets/PackageSVG';
import { SVGnewDrop } from '../../../../../../assets/PackageSVG';
import { useState } from 'react';
import EmojiPicker from 'emoji-picker-react';

const Block = Quill.import('blots/block');
Block.tagName = 'p';
Quill.register(Block);
Quill.register('modules/autoLinks', AutoLinks);

export const SendForm = (props) => {
  const { messageText, setMessageText, sendMessage, title, listingId } = props;

  const reactQuillRef = useRef(null);

  const [isShowEmoji, setIsShowEmoji] = useState(false);

  useEffect(() => {
    if (title) {
      setMessageText(
        `<a href="http://${window.location.host}/listings?id=${listingId}">${title}</a>`
      );
    }
  }, []);

  function handleChange(value, delta, source, editor) {
    setMessageText(value.replace('<p><br></p>', ''));
  }

  const modules = useMemo(() => {
    return {
      toolbar: [[false]],
      autoLinks: true,
    };
  }, []);

  const formats = ['link'];

  const handleKeyPress = (event) => {
    if ((event.key === 'Enter') & !event.shiftKey) {
      sendMessage(event);
      setIsShowEmoji(false);
    }
  };

  const handleDrop = (acceptedFiles) => {};
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  const handleToggleEmojiShow = () => {
    setIsShowEmoji(!isShowEmoji);
  };

  const handleEmojiClick = (e) => {
    setMessageText(messageText + e.emoji);
  };

  return (
    <form className={styles.form}>
      <ReactQuill
        ref={reactQuillRef}
        value={messageText}
        placeholder="Message"
        onChange={handleChange}
        onKeyUp={handleKeyPress}
        className={styles.reactQuill_textArea}
        modules={modules}
        formats={formats}
      />
      <div>
        {isShowEmoji && (
          <div className={styles.emoji}>
            <EmojiPicker emojiStyle="twitter" onEmojiClick={handleEmojiClick} />
          </div>
        )}
        <div className={styles.sendForm__send__block}>
          {/* <div className={styles.attachments} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={styles.backgroundAttachment}>
              <SVGnewDrop fill='#173593' stroke='#173593' />
            </div>
          </div> */}

          <div
            onClick={handleToggleEmojiShow}
            className={styles.backgroundAttachment}
          >
            <SVGfaceSmile fill="#173593" stroke="#173593" />
          </div>
          <button
            onClick={(e) => {
              sendMessage(e);
              setIsShowEmoji(false);
            }}
          >
            <span>Send</span>
          </button>
        </div>
      </div>
    </form>
  );
};
