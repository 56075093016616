import React from 'react';

import _ from 'lodash';

import FormNotes from '../sharedComponents/FormNotes';
import LPButton from '../../../components/ui/button/LPButton';
import FormExperience from '../sharedComponents/FormExperience';
import FormListingSites from '../sharedComponents/FormListingSites';
import FormSpecialties from '../sharedComponents/formSpecialties/FormSpecialties';
import FormLocationPref from '../sharedComponents/FormLocationPref';
import FormServices from '../sharedComponents/FormServices';
import FormCharges from '../sharedComponents/formCharges/FormCharges';

import cl from './ConsignerAssistSellerSect.module.scss';

const ConsignerAssistSellerSect = ({
  setSaveBtnDisabled,
  saveBtnDisabled,
  locationPref,
  setLocationPref,
  experience,
  setExperience,
  charges,
  setCharges,
  services,
  setServices,
  specialties,
  setSpecialties,
  notes,
  setNotes,
  siteInput,
  setSiteInput,
  selectedShops,
  setSelectedShops,
  saveDataHandler,
  btnTitle,
  type,
  title,
}) => {
  return (
    <>
      {/* location preferences */}
      {type === 'seller' ? null : (
        <div className={cl.section}>
          <FormLocationPref
            {...{ setSaveBtnDisabled, setLocationPref, locationPref, type }}
          />
        </div>
      )}

      {/* experience */}
      <div className={cl.section}>
        <FormExperience
          {...{ setSaveBtnDisabled, setExperience, experience, title }}
        />
      </div>

      {/* listing sites */}
      <div className={cl.section}>
        <FormListingSites
          {...{
            setSaveBtnDisabled,
            saveBtnDisabled,
            setSiteInput,
            siteInput,
            selectedShops,
            setSelectedShops,
            type,
          }}
        />
      </div>

      {/* specialties */}
      <div className={cl.section}>
        <FormSpecialties
          {...{ setSaveBtnDisabled, specialties, setSpecialties, type }}
        />
      </div>

      {/* services */}
      {type === 'seller' ? null : (
        <div className={cl.section}>
          <FormServices
            {...{ setSaveBtnDisabled, services, setServices, type }}
          />
        </div>
      )}

      {/* charges*/}
      {type === 'seller' ? null : (
        <div className={cl.section}>
          <FormCharges {...{ setSaveBtnDisabled, charges, setCharges, type }} />
        </div>
      )}

      {/* notes */}
      <div className={cl.section}>
        <FormNotes {...{ notes, setNotes, setSaveBtnDisabled, title }} />
      </div>

      <LPButton
        disabled={saveBtnDisabled[type]}
        className={[cl.saveBtn, cl[[type]]].join(' ')}
        onClick={saveDataHandler}
        title={btnTitle}
      >
        Save {title} Profile!
      </LPButton>
    </>
  );
};

export default ConsignerAssistSellerSect;
