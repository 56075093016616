import React, { useState } from 'react';

import { SVGbin } from '../../../assets/PackageSVG';
import Tooltip from '../../../components/ui/tooltip/Tooltip';
import Input from '../../../components/ui/input/Input';
import Checkbox from '../../../components/ui/checkbox/Checkbox';

import cl from './FormListingSites.module.scss';

const labelShops = [
  'Depop',
  'eBay',
  'Etsy',
  'Facebook',
  'Grailed',
  'Instagram',
  'Kidizen',
  'Mercari',
  'Poshmark',
  'Shopify',
  'Tradesy',
  'Amazon',
];

const FormListingSites = ({
  setSaveBtnDisabled,
  siteInput,
  setSiteInput,
  selectedShops,
  setSelectedShops,
  type,
}) => {
  const handleSiteChange = (value, InputId) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    const newSiteValue = siteInput.map((site) => {
      if (site.id !== InputId) return site;
      site.site = value.value;
      return site;
    });
    setSiteInput(newSiteValue);
  };

  const handleRemoveSite = (index) => {
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    let components = [...siteInput].filter(({ id }) => id !== index);
    setSiteInput(components);
  };

  const handleAddSite = (e) => {
    e.preventDefault();
    setSaveBtnDisabled((prev) => ({ ...prev, [type]: false }));
    const currentIds = siteInput.map((site) => site.id);
    if (currentIds.length >= 4) return;
    let i = 0;
    let id = '';
    while (i <= 3) {
      id = i;
      if (!currentIds.includes(id)) {
        break;
      }
      i++;
    }
    if (id > 4) return;
    setSiteInput((prev) => [...prev, { id: id, site: '' }]);
  };

  return (
    <>
      <div className={cl.heading}>
        <div style={{ display: 'flex' }}>
          <h2>What are your preferred listing sites?</h2>
          <Tooltip>
            <p>
              Which sites do you prefer to list on or have the most experience
              with as an assistant? Use the note section to provide additional
              details.
            </p>
          </Tooltip>
        </div>
        <p>Select all that apply.</p>
      </div>

      <div className={cl.checkmarkBoxContainer}>
        {labelShops.map((label) => (
          <Checkbox
            key={label}
            data={selectedShops}
            setData={setSelectedShops}
            {...{ setSaveBtnDisabled, type, label }}
          />
        ))}
      </div>

      {siteInput?.length > 0 ? (
        <div className={cl.addSiteContainer}>
          {siteInput.map((site) => (
            <div
              key={site.id}
              className={[
                cl.addSiteDiv,
                site?.status ? cl['active'] + ' ' + cl[type] : '',
              ].join(' ')}
            >
              <Input
                maxLength="12"
                required={false}
                placeholder="Add site"
                addClass="site"
                name="site"
                data={site.site}
                inputType="text"
                index={site.id}
                onChange={handleSiteChange}
                setSiteInput={setSiteInput}
                siteInput={siteInput}
                active={site.status}
                type={type}
              />
              <SVGbin
                key={site.id + 1}
                className={cl.binSvg}
                onClick={() => handleRemoveSite(site.id)}
              ></SVGbin>
            </div>
          ))}
        </div>
      ) : null}

      <div className={cl.addServBox}>
        <button
          className={[cl.addBox, cl[[type]]].join(' ')}
          onClick={handleAddSite}
        >
          + Add Site
        </button>
      </div>
    </>
  );
};
export default FormListingSites;
