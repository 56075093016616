import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {},
  metadata: { offset: 0, limit: 10 },
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...action.payload };
    },
    setMetadata: (state, action) => {
      state.metadata = { ...action.payload };
    },
  },
});

export const { setFilters, setMetadata } = filtersSlice.actions;
export default filtersSlice.reducer;
