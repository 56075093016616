import React, { useState } from 'react';
import CardStyles from './Card.module.scss';
import './Card.css';
import { useDispatch } from 'react-redux';
import { setInitialSlide } from '../../redux/slices/sliderSlice';
const CardItem = ({
  id,
  name,
  nameDesc,
  desc,
  color,
  vector,
  setReadMore,
  readMore,
  index,
  clickEvent,
  image,
}) => {
  const imagePath = require(`../../assets/picture/${vector}.jpg`);

  const dispatch = useDispatch();

  const handleClick = () => {
    setReadMore(!readMore);
    clickEvent(index);
    // dispatch({
    //   type: "SETINITIALSLIDE",
    //   payload: index,
    // });
    dispatch(setInitialSlide(index));
  };

  return (
    <div
      className={readMore ? CardStyles.cardItemFull : CardStyles.cardItem}
      style={{ border: `1px solid ${color}` }}
    >
      <div
        className={CardStyles.avatarContainer}
        style={{ backgroundColor: color }}
      >
        <div className={CardStyles.avatarBox}>
          <img src={image ? require(`../../assets/picture/${image}`) : ''} />
        </div>
        <div className={CardStyles.info}>
          <h2>{name}</h2>
          <p>{nameDesc}</p>
        </div>
      </div>

      <div className={CardStyles.descContainer}>
        <p className={readMore ? CardStyles.descFull : CardStyles.desc}>
          <img className={CardStyles.vector} src={imagePath} />
          {desc}
        </p>
      </div>
      <div className={CardStyles.readMore} onClick={handleClick}>
        {readMore ? 'Close' : 'Read more'}
      </div>
    </div>
  );
};

export default CardItem;
