import React from 'react';
import { SVGbin } from '../../../assets/PackageSVG';
import cl from './RemoveIcon.module.scss';

const RemoveIcon = ({ removeField, index, addClass }) => {
  return (
    <>
      <div
        className={
          !addClass ? cl.removeBtnBox : [cl.removeBtnBox, cl.charges].join(' ')
        }
      >
        <SVGbin
          className={!addClass ? cl.icon : [cl.icon, cl.charges].join(' ')}
          onClick={(e) => removeField(e, index)}
        />
      </div>
    </>
  );
};

export default RemoveIcon;
