import React, { useState } from 'react';

import Tooltip from '../../../components/ui/tooltip/Tooltip';
import Checkbox from '../../../components/ui/checkbox/Checkbox';
import SmallSpinner from '../../../components/ui/smallSpinner/SmallSpinner';

import cl from './UsertypesSect.module.scss';

const UsertypesSection = ({ setSaveBtnDisabled }) => {
  const labelTypes = ['Seller', 'Assistant', 'Consigner', 'Buyer'];
  const [showMsg, setShowMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className={cl.heading}>
      <h2>What do you do?</h2>
      <Tooltip>
        <span>
          These selections will be used to search for other partiers in the
          directory. As Listing Party is further developed, we’ll have more
          options for each selection you make.
        </span>
        <ul>
          <li>
            Seller: You are actively selling goods online or in a physical shop.
          </li>
          <li>
            Assistant: Select if you offer VA or similar services to assist
            sellers in day to day management of their business. You may offer a
            specialized skill set or general services locally or digitally.
          </li>
          <li>
            Consignor: You offer consignment services to sell goods or products
            on behalf of another party.{' '}
          </li>
          <li>
            Buyer: You are actively seeking to purchase goods and products from
            other partiers.{' '}
          </li>
        </ul>
      </Tooltip>
      <p>Select all that apply.</p>
      <div className={cl.checkmarkBoxContainer}>
        {labelTypes.map((label) => (
          <Checkbox
            key={label}
            usertypesSection={true}
            {...{ setSaveBtnDisabled, setShowMsg, setLoading, label }}
          />
        ))}
      </div>
      {loading ? (
        <SmallSpinner />
      ) : showMsg ? (
        <>
          <p>{showMsg}</p>
        </>
      ) : null}
    </div>
  );
};

export default UsertypesSection;
