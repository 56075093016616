import React, { useState, useEffect, useCallback, useRef } from 'react';
import useUsers from '../../hooks/useUsers';
import moment from 'moment';

import Goal from './Goal';
import Loader from '../../components/Container/Loader';

import cl from './GoalsContainer.module.scss';

const currentDate = moment().format();

const GoalsContainer = ({ tab, setCopyGoal }) => {
  const [metadata, setMetadata] = useState({ offset: 0, limit: 10 });
  const filter_by =
    tab === 'completed'
      ? {}
      : tab === 'overdue'
      ? { due_date_range: { end: currentDate } }
      : tab === 'upcoming' && { due_date_range: { start: currentDate } };
  const [toRender, setToRender] = useState([]);
  const [sortType, setSortType] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  //
  //
  //

  const { goalsList } = useUsers();

  //
  //
  //

  const observer = useRef(null);
  const lastGoalRef = useCallback(
    (node) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          let updOffset = metadata.offset + 10;
          setMetadata((prev) => ({ ...prev, offset: updOffset }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore],
  );

  //
  //
  //

  useEffect(() => {
    setIsFetching(true);
    goalsList(filter_by, metadata).then(
      (data) => {
        if (data?.data) {
          const filteredData = data.data.filter(({ status }) => {
            return status !== 'deleted';
          });
          const { offset } = metadata;
          if (tab === 'completed') {
            const filtered = filteredData.filter(({ status }) => {
              return status === 'completed';
            });
            setToRender((prev) => {
              return offset === 0 ? [...filtered] : [...prev, ...filtered];
            });
            setHasMore(filtered.length > 0);
          } else {
            let filtered = filteredData.filter(({ status }) => {
              return status !== 'completed';
            });
            const toRenderRest = filtered.sort(function (a, b) {
              let aDate = Math.floor(new Date(a.due_date).getTime() / 1000);
              let bDate = Math.floor(new Date(b.due_date).getTime() / 1000);
              return aDate - bDate;
            });
            setToRender((prev) => {
              return offset === 0
                ? [...toRenderRest]
                : [...prev, ...toRenderRest];
            });
            setHasMore(toRenderRest.length > 0);
          }
          setIsFetching(false);
        }
      },
      (error) => {
        console.error('Error getting goals', error);
      },
    );
  }, [metadata]);

  useEffect(() => {
    if (sortType.length > 0) {
      let updToRender = [];
      if (sortType === 'dueDateSoonestFirst') {
        updToRender = toRender.sort(function (a, b) {
          let aDate = Math.floor(new Date(a.due_date).getTime() / 1000);
          let bDate = Math.floor(new Date(b.due_date).getTime() / 1000);
          return aDate - bDate;
        });
      } else if (sortType === 'dueDateFurthestFirst') {
        updToRender = toRender.sort(function (a, b) {
          let aDate = Math.floor(new Date(a.due_date).getTime() / 1000);
          let bDate = Math.floor(new Date(b.due_date).getTime() / 1000);
          return bDate - aDate;
        });
      } else if (sortType === 'created') {
        updToRender = toRender.sort(function (a, b) {
          let aDate = Math.floor(new Date(a.created_at).getTime() / 1000);
          let bDate = Math.floor(new Date(b.created_at).getTime() / 1000);
          return bDate - aDate;
        });
      } else if (sortType === 'updated') {
        updToRender = toRender.sort(function (a, b) {
          let aDate = Math.floor(new Date(a.updated_at).getTime() / 1000);
          let bDate = Math.floor(new Date(b.updated_at).getTime() / 1000);
          return bDate - aDate;
        });
      }
      setToRender([...updToRender]);
    }
  }, [sortType]);

  //add error handling

  return (
    <>
      <Loader flex isFetching={isFetching && metadata && metadata.offset === 0}>
        <div className={[cl.cardContainer, cl[tab]].join(' ')}>
          <div className={cl.sort}>
            <label htmlFor="sort-select"></label>
            <select
              name="sorting"
              id="sort-select"
              onChange={(e) => {
                setSortType(e.target.value);
              }}
            >
              <option value="">--Sort by--</option>
              <option value="dueDateSoonestFirst">
                By due date soonest first
              </option>
              <option value="dueDateFurthestFirst">
                By due date furthest first
              </option>
              <option value="created">Recently created first</option>
              <option value="updated">Recently updated first</option>
            </select>
          </div>
          {toRender.length > 0
            ? toRender.map((one, index) => {
                const { data, id } = one;

                return toRender.length !== index + 1 ? (
                  <Goal
                    key={id}
                    {...{
                      one,
                      data,
                      tab,
                      index,
                      setCopyGoal,
                    }}
                    total={toRender.length}
                    goals={toRender}
                    setGoals={setToRender}
                  />
                ) : (
                  <div key={id}>
                    <Goal
                      {...{
                        one,
                        data,
                        tab,
                        index,
                        setCopyGoal,
                      }}
                      goalNum={index + 1}
                      total={toRender.length}
                      goals={toRender}
                      setGoals={setToRender}
                    />
                    <div ref={lastGoalRef}></div>
                  </div>
                );
              })
            : 'No goals to show'}
        </div>
        {isFetching && metadata && metadata.offset > 0 && (
          <div className={cl.loadingDiv}>Loading...</div>
        )}
      </Loader>
    </>
  );
};

export default GoalsContainer;
