import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import useUsers from '../../hooks/useUsers';

import DashboardCard from './DashboardCard';
import Container from '../../components/Container/Container';
import Loader from '../../components/Container/Loader';

import cl from './DashboardPage.module.scss';
import { useDispatch } from 'react-redux';

import { setBlockState } from '../../redux/slices/bloskStateSlice';

const list = [
  {
    page: 'video',
    link: '',
    title: 'Learn about Listing Party!',
    description: '',
    btnText: '',
  },
  {
    page: 'profile',
    link: '/profile/edit',
    title: 'Create or edit your profile',
    description:
      'Edit your profile for other Partiers to see! You can choose what to show publicly.',
    btnText: 'Edit Profile',
  },
  {
    page: 'events',
    link: '/events',
    title: 'Join a live Listing Party',
    description:
      'Connect with fellow Partiers, get tips from experts, and more!',
    btnText: 'Go to Events',
  },
  {
    page: 'directory',
    link: '/directory?types=assistant&types=consigner',
    title: 'Find a Partier to help with your business',
    description: 'You can search for Assistants and Consigners.',
    btnText: 'Find Assistants',
  },
  {
    page: 'listings',
    link: '/listings',
    title: 'Source from and support fellow Partiers',
    description: 'Browse listings and purchase inventory from other Partiers.',
    btnText: 'Go to Listings',
  },
];

const DashboardPage = () => {
  const { usersSelf } = useUsers();
  // const { isFetching, error, data, refetch } = useQuery(['usersSelf'], () => usersSelf());
  // const adata = data?.data;

  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const adata = data?.data;

  useEffect(() => {
    usersSelf().then((data) => {
      setData(data);
      setIsFetching(false);
    });
  }, []);

  useEffect(() => {
    if (!adata) return;
    dispatch(
      setBlockState({
        ban: adata?.status === 'suspended',
        noUsername: !adata?.username,
      }),
    );
  }, [adata]);

  //
  //
  //

  return (
    <Loader flex borderless isFetching={isFetching}>
      <Container flex borderless className={cl.mainContainer}>
        {adata?.username ? (
          <h1>
            Hello <span>{adata?.username}</span>! Are you ready to party?
            &#127881;
          </h1>
        ) : (
          <h1>
            Hi there! Let’s get the Party started and create your profile.
          </h1>
        )}

        {list.map(({ page, title, description, btnText, link }) => (
          <DashboardCard
            key={title}
            {...{
              page,
              title,
              description,
              btnText,
              link,
              disabled: adata?.username ? '' : 'disabled',
            }}
          />
        ))}
      </Container>
    </Loader>
  );
};

export default DashboardPage;
