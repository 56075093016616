import styles from './Box.module.scss';

export const Box = ({ direction, children }) => {
  return (
    <div
      style={{
        borderRight: direction === 'topRight' && '1px solid #B1B1B1',
        borderTop: direction === 'topRight' && '1px solid #B1B1B1',
      }}
      // className={styles.box }>{children}</div>;
      className={
        direction === 'topRight'
          ? `${styles.box} leftBox`
          : `${styles.box} rightBox`
      }
    >
      {children}
    </div>
  );
};
