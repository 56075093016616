// import PropTypes from 'prop-types'
import React from 'react';
import moment from 'moment';

import * as dates from 'react-big-calendar/lib/utils/dates';
import { navigate } from 'react-big-calendar/lib/utils/constants';

import axios from 'axios';
// import Modal from "../../../components/Modal/Modal";
// import cl from "../../../components/Modal/Modal.module.scss";
import YearTooltip from './YearToolTip';

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment();
  } else {
    currentDate = moment(currentDate);
  }

  const first = currentDate.clone().startOf('month');
  const last = currentDate.clone().endOf('month');

  const weeksCount = Math.ceil((first.day() + last.date()) / 7);
  const calendar = Object.assign([], { currentDate, first, last });

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = [];
    calendar.push(week);
    calendar.year = currentDate.year();
    calendar.month = currentDate.month();

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set('date', day + 1 - first.day());
      date.calendar = calendar;
      week.push(date);
    }
  }

  return calendar;
}

function CalendarDate(props) {
  const { dateToRender, dateOfMonth, activeClass } = props;

  const today =
    dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? 'today'
      : '';

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    );
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    );
  }

  return (
    <button
      className={`date in-month ${today}`}
      onClick={() => props.onClick(dateToRender)}
    >
      {dateToRender.date()}
    </button>
  );
}

class Calendar extends React.Component {
  state = {
    calendar: undefined,
    eventsList: [],
    isLoading: false,
    error: null,
    metadata: { offset: 0, limit: 10 },
    openToolTip: false,
    dateForModal: '',
    activeClass: false,
  };
  handleClose = () => {
    this.setState({ openToolTip: !this.state.openToolTip });
    this.setState({ eventsList: [] });
  };

  handleButtonClick = (dt) => {
    this.setState({ isLoading: true });
    this.setState({ openToolTip: !this.state.openToolTip });
    //
    const givenDate = new Date(dt);
    const choosenDay = new Date(
      givenDate.getTime() + 24 * 60 * 60 * 1000
    ).toISOString();

    this.setState({ dateForModal: choosenDay });

    const date = new Date(choosenDay);
    date.setDate(date.getDate() - 1);
    const nextDay = date.toISOString();
    this.setState({ activeClass: !this.state.activeClass });
    const fetchData = async () => {
      const token = localStorage.lp_custom_access_token;
      try {
        const response = axios
          .post(
            'https://api.listperfectly.com/v1/listing-party/events/list',
            {
              filter_by: {
                date_range: { start: nextDay, end: choosenDay },
                status: 'public',
                types: [],
              },
              metadata: { offset: 0, limit: 10 },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.setState({ eventsList: response.data.data });
            this.setState((prevState) => ({
              metadata: {
                ...prevState.metadata,
                offset: prevState.metadata.offset + 10,
              },
            }));
          });
      } catch (error) {
        this.setState({ error });
      } finally {
        this.setState({ isLoading: false });
      }
    };
    this.setState({ eventsList: [] }, fetchData);
  };
  componentDidMount() {
    this.setState({ calendar: createCalendar(this.props.date) });
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.setState({ calendar: createCalendar(this.props.date) });
    }
  }

  render() {
    if (!this.state.calendar) {
      return null;
    }

    return (
      <div className="month">
        <div className="month-name">
          {this.state.calendar.currentDate.format('MMMM').toUpperCase()}
        </div>
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
          <span key={index} className="day">
            {day}
          </span>
        ))}
        {this.state.calendar.map((week, index) => (
          <div key={index}>
            {week.map((date) => (
              <CalendarDate
                key={date.date()}
                dateToRender={date}
                formatDt={date.format()}
                dateOfMonth={this.state.calendar.currentDate}
                onClick={(date) => this.handleButtonClick(date.format())}
                activeClass={this.state.activeClass}
              />
            ))}
          </div>
        ))}
        {this.state.openToolTip && (
          <YearTooltip
            handleClose={this.handleClose}
            eventLists={this.state.eventsList}
            choosenDate={this.state.dateForModal}
          />
        )}
      </div>
    );
  }
}

class YearModule extends React.Component {
  render() {
    let { date, ...props } = this.props;
    let range = YearModule.range(date);
    const months = [];
    const firstMonth = dates.startOf(date, 'year');

    for (let i = 0; i < 12; i++) {
      months.push(
        <Calendar key={i + 1} date={dates.add(firstMonth, i, 'month')} />
      );
    }

    return <div className="year">{months.map((month) => month)}</div>;
  }
}

YearModule.range = (date) => {
  return [dates.startOf(date, 'year')];
};

YearModule.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, 'year');

    case navigate.NEXT:
      return dates.add(date, 1, 'year');

    default:
      return date;
  }
};

YearModule.title = (date, { localizer }) =>
  localizer.format(date, 'yearHeaderFormat');

export default YearModule;
