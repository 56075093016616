import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux';

import cl from './assets/styles/App.module.scss';

import Routers from './routes/Routers';

import Header from './components/Header/Header';
import Menu from './components/Menu/Menu';
import useScreenSize from './hooks/useScreenSize';

import history from './hooks/useHistory';

import { unstable_HistoryRouter as Router } from 'react-router-dom';
import './assets/styles/index.css';
import {
  blockStateSelector,
  chatNotificationSelector,
  isAuthorizedSelector,
  isLoadingSelector,
  isProdSelector,
  tokenSelector,
} from './redux/selectors';
import UserSharedWorker from './hooks/UserSharedWorker';
import { setAuth, setIsLoading } from './redux/slices/authSlice';

const HelpPage = ({ isMobile }) => {
  const { pathname } = useLocation();

  return (
    <div
      style={{ display: pathname.includes('/chat') ? 'none' : 'flex' }}
      className={cl.help + ' ' + (isMobile ? cl.mobile : '')}
    >
      <Link to="/help">
        <span>?</span>
      </Link>
    </div>
  );
};

const App = () => {
  const dispatchRedux = useDispatch();

  const isAuthorized = useSelector(isAuthorizedSelector);
  const token = useSelector(tokenSelector);
  const isLoading = useSelector(isLoadingSelector);
  const blockState = useSelector(blockStateSelector);

  const [aMenuId, setMenuId] = useState(0);
  const { isMobile, isCollapsible } = useScreenSize();

  const [menuNotCollapsed, setMenuCollapsed] = useState(!isCollapsible);

  const menuSize =
    aMenuId === 0 || isMobile
      ? 0
      : menuNotCollapsed && !isCollapsible
      ? 300
      : 80;

  const jsstyles = useMemo(() => {
    return {
      menu: { flexBasis: menuSize },
      body: {
        flexBasis:
          !isAuthorized || isMobile ? '100%' : `calc(100% - ${menuSize}px)`,
      },
    };
  }, [menuSize, isAuthorized, isMobile]);

  const { worker } = UserSharedWorker();

  useEffect(() => {
    if (!token && localStorage.lp_custom_access_token) {
      dispatchRedux(
        setAuth({
          token: localStorage.lp_custom_access_token,
          isAuthorized: true,
        }),
      );
    }

    dispatchRedux(setIsLoading(false));
  }, []);

  // console.log('APP rendered with redux toolkit')

  return (
    <>
      {isLoading ? null : (
        <div className={cl.wrapper}>
          <Router history={history}>
            {isMobile ? null : <Header className={cl.header}></Header>}

            {!isAuthorized ? null : (
              <div className={cl.menu} style={jsstyles.menu}>
                <Menu
                  {...{
                    aMenuId,
                    setMenuId,
                    isMobile,
                    menuNotCollapsed,
                    setMenuCollapsed,
                    blockState,
                  }}
                ></Menu>
              </div>
            )}

            <div className={cl.body} style={jsstyles.body}>
              <>
                {!isAuthorized ? null : (
                  <HelpPage isMobile={isMobile}></HelpPage>
                )}
                <Routers />
              </>
            </div>
          </Router>
        </div>
      )}
    </>
  );
};

export default App;
