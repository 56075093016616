import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sliderIndex: 0,
  initialSlide: 0,
};

const sliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {
    setSliderIndex: (state, action) => {
      state.sliderIndex = action.payload;
    },
    setInitialSlide: (state, action) => {
      state.initialSlide = action.payload;
    },
  },
});

export const { setSliderIndex, setInitialSlide } = sliderSlice.actions;
export default sliderSlice.reducer;
