export const removeUnderscore = (str) => {
  const strArr = str.split('_');

  if (strArr.length > 1) {
    return strArr[0] + '\n' + strArr[1];
  }

  return strArr.join(' ');
};

export function capitalizedLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
