import moment from 'moment';

export const sortedData = (arr, userInfoData) => {
  arr.reverse().forEach((item) => {
    const dateString = item.created_at;
    const date = moment(dateString);
    const formattedTime = date.format('h:mm A');
    const formattedDate = date.format('dddd, MMMM Do YYYY');

    if (item.user_id === userInfoData.id) {
      item.inComing = false;
      item.data = item.user;
      item.created_date = formattedDate;
      item.created_time = formattedTime;
      item.key = item.id;
    } else {
      item.inComing = true;
      item.data = item.user;
      item.created_date = formattedDate;
      item.created_time = formattedTime;
      item.key = item.id;
    }
  });

  return arr;
};
