const htmlUnescapes = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#39;': "'",
};

const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?39);/g;
const reHasEscapedHtml = RegExp(reEscapedHtml.source);

//

const mps = [
  'amazon',
  'depop',
  'ebay',
  'etsy',
  'facebook',
  'grailed',
  'instagram',
  'kidizen',
  'mercari',
  'poshmark',
  'shopify',
  'tradesy',
];
const img_placeholder = `${window.location.origin}/images/marketplaces/placeholder.png`;

//
//
//

const useBasicFuncs = () => {
  const bdecode = (s) =>
    s && reHasEscapedHtml.test(s)
      ? s.replace(reEscapedHtml, (m) => htmlUnescapes[m] || "'")
      : s || '';

  //

  const getMpsImage = (mp) => {
    if (!mp || typeof mp !== 'string') return img_placeholder;

    mp = mp.toLowerCase();
    if (!mps.includes(mp)) return img_placeholder;

    return `${window.location.origin}/images/marketplaces/${mp}.png`;
  };

  //

  const bakeRandomString = (length, encode = false) => {
    let result = '';
    const charSet = '!@#$%^&*(){}[]+_-~"№;:?';
    const charLen = charSet.length;
    for (let i = 0; i < length; i++)
      result += charSet.charAt(Math.floor(Math.random() * charLen));
    return encode ? encodeURIComponent(result) : result;
  };

  //

  const cryptString = (v, tf = 0) =>
    v
      .split('')
      .map((a) => String.fromCharCode(a.charCodeAt(0) + (tf ? 1 : -1)))
      .join('');

  //

  return { bdecode, getMpsImage, bakeRandomString, cryptString };
};

//
//
//

export default useBasicFuncs;
