import React from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import {
  SVGhandwave,
  SVGlocationdot,
  SVGlocationpinslash,
  SVGpaperplanetop,
} from '../../assets/PackageSVG';

import useBasicFuncs from '../../hooks/useBasicFuncs';
import useUsers from '../../hooks/useUsers';

import ColoredIcon from './ColoredIcon';
import TypeProfile from './TypeProfile';
import Container from '../../components/Container/Container';
import Loader from '../../components/Container/Loader';
import Image from '../../components/ui/image/Image';
import LPButton from '../../components/ui/button/LPButton';
import cl from './ProfilePage.module.scss';
import Badge from '../../components/Badge/Badge';
import { badgeArr } from './mockBadgesData';

import UserSharedWorker from '../../hooks/UserSharedWorker';
import { useDispatch, useSelector } from 'react-redux';
import {
  setChatMetadata,
  setChatNotification,
} from '../../redux/slices/chatSlice';
import { mediatorSelector } from '../../redux/selectors';

const ProfilePage = () => {
  const { user_id } = useParams();
  const [filter_by, setFilter_by] = useState({ id: user_id });

  const [isLocal, setIsLocal] = useState(!user_id);
  const [badges, setBadges] = useState([]);
  const [filteredBadges, setFilteredBadges] = useState([]);
  const [badgeModal, setBadgeModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({ id: '' });

  useEffect(() => {
    setIsLocal(!user_id);
    setFilter_by({ id: user_id });
  }, [user_id]);

  const options = {
    include_badges: true,
  };

  const { metadata = { offset: 0, limit: 200 } } =
    useSelector(mediatorSelector);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { bdecode, bakeRandomString, cryptString } = useBasicFuncs();
  const { usersSelf, usersList } = useUsers();

  const { isFetching, error, data, refetch } = useQuery(
    ['usersListOrSelf' + +isLocal, filter_by],
    () =>
      isLocal
        ? usersSelf(options)
        : usersList(filter_by, { offset: 0, limit: 1 }, options),
  );

  //
  //
  //
  const { worker } = UserSharedWorker();
  //

  useEffect(() => {
    usersSelf().then((data) => {
      if (data?.data) {
        setCurrentUser(data.data);
      }
    });
  }, []);

  const adata = isLocal ? data?.data : data?.data?.[0];

  // single
  const bio = adata?.bio;
  const types = adata?.types || [];
  const username = adata?.username;
  const emailaddress = adata?.email?.email || '';
  const accountstatus = adata?.status || '';
  const {
    public: is_profile_public,
    last_name,
    first_name,
  } = adata?.name || {};
  const {
    public: is_location_public,
    city,
    state,
    country,
    zip,
  } = adata?.location || {};
  const id = adata?.auth_provider_user_id;

  // photo
  const photo_key = adata?.photo?.key;
  const profile_url =
    photo_key && 'https://s3.amazonaws.com/cdn.listingparty.com/' + photo_key;

  const profileId = adata?.id;

  const { dmAdd } = useUsers();

  const handleSendMessage = (e) => {
    const query = {
      sh: bakeRandomString(19, 1),
      cut: new Date().getTime(),
      el: bakeRandomString(8, 1),
      'contact-member': cryptString(e),
      'nenaivuehi-aqeruhiu3h': encodeURIComponent(
        (Math.random() + 1).toString(36).substring(2),
      ),
      uha: bakeRandomString(13, 1),
    };

    window
      .open(
        'https://app.listperfectly.com/listing-party/contact/?' +
          Object.entries(query)
            .map(([k, v]) => k + '=' + v)
            .join('&'),
        '_blank',
      )
      .focus();
  };

  const handleRedirect = async () => {
    try {
      const response = await dmAdd(user_id);

      if (response?.data) {
        const chat_id = response.data.id;

        dispatch(setChatMetadata({ offset: 0, limit: 10 }));

        dispatch(setChatNotification(false));

        navigate(`/chat/${chat_id}`, {
          state: { chatUser: data?.data[0], type: 'dm' },
        });
      }
    } catch (error) {
      console.error(error, 'error');
    }
  };

  const check1 = !is_profile_public && !username;
  const check2 = !is_location_public || !(city || state || zip);
  const check_displayProfile = !(check1 && check2);
  const check_banned = isLocal && accountstatus === 'suspended';

  useEffect(() => {
    let temp = [];
    const newTemp = [];
    adata?.badges?.map((bgItem) => {
      if (bgItem?.user_id === adata?.id) {
        // eslint-disable-next-line no-lone-blocks

        badgeArr?.map((item) => {
          if (item.type === bgItem.code) {
            temp.push(item);
          }
        });
      }
    });

    temp.map((badge) => {
      if (badge.type === 'early_adopter') {
        newTemp[0] = badge;
      } else if (badge.type === 'listing') {
        newTemp[1] = badge;
      } else {
        newTemp.push(badge);
      }
    });
    setFilteredBadges(newTemp);
  }, [badges, adata]);

  return (
    <Loader isFetching={isFetching || !adata}>
      {!check_banned ? null : (
        <Container
          className={cl.banNotice}
          title="Important notice"
          titleColor="red"
        >
          <span>Your Listing Party account is currently suspended.</span>
          <span>If you have any questions please reach out to support</span>
          <LPButton
            onClick={() =>
              window.open('https://app.listperfectly.com/support/', '_blank')
            }
          >
            Contact Support
          </LPButton>
        </Container>
      )}

      <Container className={cl.wrapper}>
        {check_displayProfile ? (
          <>
            <div className={cl.block}>
              <div className={cl.line}>
                <div className={cl.image}>
                  <Image src={profile_url} alt="profile"></Image>
                </div>
                <div className={cl.rest}>
                  <div className={cl.left}>
                    <div className={cl.names}>
                      {is_profile_public ? (
                        <>
                          {!first_name ? null : (
                            <span>{bdecode(first_name)}</span>
                          )}
                          {!last_name ? null : (
                            <span>{bdecode(last_name)}</span>
                          )}
                        </>
                      ) : null}
                      {!username ? null : (
                        <span title={username}>@{username}</span>
                      )}
                    </div>

                    {check2 ? null : (
                      <div className={cl.loca}>
                        {state ? (
                          <>
                            <SVGlocationdot />
                            <span>{state}</span>
                            {city ? <span>{city}</span> : null}
                          </>
                        ) : zip ? (
                          <>
                            <SVGlocationpinslash title="Specify your location" />
                            <span>Zipcode: {zip}</span>
                          </>
                        ) : null}
                      </div>
                    )}
                  </div>

                  <div className={cl.messageButton}>
                    {!username ? null : (
                      <LPButton
                        line
                        onClick={() => navigate('/listings?u_id=' + id)}
                      >
                        {isLocal ? 'My ' : ''}
                        Listings
                      </LPButton>
                    )}

                    {isLocal ? null : (
                      // <LPButton title='Redirect' disabled={!emailaddress} onClick={()=>handleSendMessage(emailaddress)}>
                      // 	<SVGpaperplanetop fill='#fff' width={17} height={17} style={{marginBottom: '-2px'}}/>
                      // 	Message
                      // </LPButton>
                      <LPButton
                        title="Redirect"
                        disabled={profileId === currentUser.id}
                        onClick={handleRedirect}
                      >
                        <SVGpaperplanetop fill='#fff' width={17} height={17} style={{marginBottom: '-2px'}}/>
                        Message
                      </LPButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {
              <div className={cl.badgeSection}>
                {filteredBadges?.length != 0 && (
                  <div className={cl.badgeTitle}>
                    <h3>Badges</h3>
                    <span>Click to learn more</span>
                  </div>
                )}
                <div className={cl.badgeContainer}>
                  {filteredBadges?.map((item, index) => {
                    return (
                      <Badge
                        imgSrc={item.imgSrc}
                        description={item.description}
                        date={item.date}
                        title={item.title}
                        name={first_name}
                        badgeLists={filteredBadges}
                        index={item.id}
                        defaultLists={badgeArr}
                        // badgeItem={removeUnderscore(bgItem.code)}
                      />
                    );
                  })}
                </div>
              </div>
            }
            {bio ? (
              <div className={cl.block}>
                <div className={cl.headline}>
                  <ColoredIcon color="blue">
                    <SVGhandwave />
                  </ColoredIcon>
                  <h4>About Me</h4>
                </div>
                <pre>{bdecode(bio)}</pre>
              </div>
            ) : null}
          </>
        ) : (
          <div className={cl.block}>
            <div className={cl.notEnough}>
              <span>
                Hello Partier, you have not entered enough details to display
                your profile
              </span>
              <div className={cl.navigator}>
                <span>Complete your profile now</span>
                <LPButton onClick={() => navigate(`/profile/edit`)}>
                  Edit Profile
                </LPButton>
              </div>
            </div>
          </div>
        )}
      </Container>

      {check_displayProfile ? (
        <>
          {Object.entries(adata?.types_metadata || {})?.map(([key, value]) => (
            <TypeProfile
              key={key}
              isFetching={isFetching}
              hidden={!types?.includes(key)}
              akey={key}
              adata={value}
            ></TypeProfile>
          ))}
        </>
      ) : null}
    </Loader>
  );
};

export default ProfilePage;
