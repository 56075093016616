import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import Login from '../pages/LoginPage/LoginPage';
import Logout from '../pages/Logout';
import Authentication from '../pages/Authentication';
import EditProfile from '../pages/EditProfile';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import Directory from '../pages/DirectoryPage/DirectoryPage';
import ListingsPage from '../pages/ListingsPage/ListingsPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import Settings from '../pages/ComingSoon';
import InboxPage from '../pages/InboxPage/InboxPage';
import TestPage from '../pages/TestPage/TestPage';
import RedirectPage from '../pages/RedirectPage';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import EventsPage from '../pages/EventsPage/EventsPage';
import SplashPage from '../pages/SplashPage/SplashPage';
import MaintenancePage from '../pages/ErrorPage/MaintenancePage';
import GoalsPage from '../pages/GoalsPage/GoalsPage';
import HomePage from '../pages/HomePage/HomePage';
//
//
import { ChatPage } from '../pages/ChatPage';
import { useSelector } from 'react-redux';
import {
  blockStateSelector,
  isAuthorizedSelector,
  isProdSelector,
} from '../redux/selectors';

const RequireAuth = ({ children, isAuthorized }) => {
  const location = useLocation();
  if (!isAuthorized)
    return <Navigate to="/login" state={{ from: location }} replace />;
  else return children;
};

const Routers = () => {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const isProd = useSelector(isProdSelector);
  const { ban, noUsername } = useSelector(blockStateSelector);

  return (
    <Routes>
      <Route
        path="/404"
        element={
          <RequireAuth isAuthorized={isAuthorized}>
            <ErrorPage />
          </RequireAuth>
        }
      ></Route>

      <Route
        path="/profile"
        element={
          <RequireAuth isAuthorized={isAuthorized}>
            <ProfilePage />
          </RequireAuth>
        }
      >
        <Route path=":user_id" element={<ProfilePage />} />
      </Route>
      <Route
        path="/chat"
        element={
          <RequireAuth isAuthorized={isAuthorized}>
            <ChatPage />
          </RequireAuth>
        }
      >
        <Route path=":user_id" element={<ProfilePage />} />
      </Route>
      <Route
        path="/profile/edit"
        element={
          <RequireAuth isAuthorized={isAuthorized}>
            <EditProfile />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/listperfectly"
        element={
          <RequireAuth isAuthorized={isAuthorized}>
            <RedirectPage path="https://app.listperfectly.com/account/" />
          </RequireAuth>
        }
      ></Route>
      {(!ban || !noUsername) && !isAuthorized && (
        <>
          <Route
            path="/events"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <EventsPage />z
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/directory"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <Directory />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/listings"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <ListingsPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/goals"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <GoalsPage />
              </RequireAuth>
            }
          ></Route>
        </>
      )}

      {ban || noUsername ? (
        <>
          <Route
            path="/events"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <Navigate to="/profile/edit" />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/directory"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <Navigate to="/profile/edit" />
              </RequireAuth>
            }
          ></Route>
          {/* <Route
						path='/chat'
						element={
							<RequireAuth isAuthorized={isAuthorized}>
								<Navigate to="/profile/edit" />
							</RequireAuth>
						}
					>
					</Route> */}

          <Route
            path="/listings"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <Navigate to="/profile/edit" />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/goals"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <Navigate to="/profile/edit" />
              </RequireAuth>
            }
          ></Route>
        </>
      ) : (
        <>
          <Route
            path="/directory"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <Directory />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/chat"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <ChatPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/listings"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <ListingsPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/events"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <EventsPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/goals"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <GoalsPage />
              </RequireAuth>
            }
          ></Route>
        </>
      )}

      {isProd ? (
        <>
          <Route
            path="/profile/settings"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <Settings />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/profile/testpage"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <TestPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/inbox"
            element={
              <RequireAuth isAuthorized={isAuthorized}>
                <InboxPage />
              </RequireAuth>
            }
          ></Route>
          {/* <Route
						path='/goals'
						element={
							<RequireAuth isAuthorized={isAuthorized}>
								<GoalsPage />
							</RequireAuth>
						}></Route> */}
        </>
      ) : null}

      <Route
        path="/help"
        element={
          <RedirectPage path="https://app.listperfectly.com/docs/listing-party-guide/listing-party-guide/" />
        }
      ></Route>
      <Route
        path="/"
        element={!isAuthorized ? <HomePage /> : <DashboardPage />}
      ></Route>
      <Route path="/login" element={<Login />}></Route>

      <Route path="/authentication" element={<Authentication />}></Route>
      <Route path="/logout" element={<Logout />}></Route>

      {/* <Route path='*' element={<Navigate to='/404' />}></Route> */}

      <Route path="*" element={<MaintenancePage />}></Route>
    </Routes>
  );
};

export default Routers;
