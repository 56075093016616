import React, { useEffect } from 'react';

import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useUsers from '../../../../hooks/useUsers';
import YearModule from '../YearModule';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
localizer.formats.yearHeaderFormat = 'yyyy';

function MyCalendar(props) {
  const { calendarEvents, setCalendarItems } = props;
  const handleSelectedEvent = (e) => {
    setCalendarItems(e);
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor={(event) => {
          return new Date(event.date);
        }}
        endAccessor={(event) => {
          return new Date(event.date);
        }}
        views={{
          day: true,
          week: true,
          month: true,
          year: YearModule,
        }}
        onSelectEvent={(e) => handleSelectedEvent(e)}
        messages={{ year: 'Year' }}
        style={{ height: 500 }}
      />
    </div>
  );
}

export default MyCalendar;
