import React from 'react';
import cl from './Modal.module.scss';
export default function Modal({ badgeItem, count, isProfile }) {
  return (
    <div
      className={`${isProfile ? `${cl.modalProfile}` : `${cl.modalDirectory}`}`}
    >
      {isProfile ? (
        <p>{badgeItem}</p>
      ) : (
        <p>
          <h2>Earned Badges</h2>
          {count}
        </p>
      )}
    </div>
  );
}
