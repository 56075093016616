import { useState, useRef, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useUsers from '../../hooks/useUsers';

import Loader from '../../components/Container/Loader';
import Container from '../../components/Container/Container';
import ListingsList from './ListingsList';

import cl from './ListingsPage.module.scss';

import UserSharedWorker from '../../hooks/UserSharedWorker';
import { useDispatch, useSelector } from 'react-redux';
import { filtersSelector, metadataSelector } from '../../redux/selectors';
import { setMetadata } from '../../redux/slices/filtersSlice';

const ListingsPage = () => {
  const dispatch = useDispatch();

  const filters = useSelector(filtersSelector);
  const metadata = useSelector(metadataSelector);

  const { listingsList } = useUsers();

  //
  //
  //

  const [listingsData, setListingsData] = useState([]);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [total, setTotal] = useState('');

  //
  //
  //

  const [searchParams, setSearchParams] = useSearchParams();

  const { isFetching, data } = useQuery(
    ['listingsList', filters, metadata],
    () => listingsList(filters, metadata),
    {
      enabled: queryEnabled,
      initialData: [],
    },
  );

  //
  //
  //

  const observer = useRef(null);
  const lastListingRef = useCallback(
    (node) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          dispatch(setMetadata({ offset: metadata.offset + 10, limit: 10 }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore],
  );

  //
  //
  //

  const { worker } = UserSharedWorker();

  useEffect(() => {
    let hasParams = false;
    for (let [key, value] of searchParams.entries()) {
      if (!value) continue;
      if (key === 'id' || key === 'u_id' || key === '133') hasParams = true;
    }
    if (
      !queryEnabled &&
      !(Object.keys(filters || {}).length === 0 && hasParams)
    )
      setQueryEnabled(true);
  }, [filters, searchParams]);

  useEffect(() => {
    if (data && queryEnabled) {
      const { data: listings = [], metadata: { total = null, offset } = {} } =
        data || {};
      if (total || total === 0) setTotal(total);
      setListingsData((prev) => {
        return offset === 0 ? listings : [...prev, ...listings];
      });
      setHasMore(listings.length > 0);
    }
  }, [data, queryEnabled]);

  return (
    <Loader isFetching={isFetching && metadata && metadata.offset === 0}>
      <Container flex borderless>
        <div className={cl.topText}>
          <h2>Browse listings from fellow sellers!</h2>
          <p>Get inspiration or find something you’re looking for!</p>
          {/* <p>{total === 0 || total === null ? 'No' : total} results.</p> */}
        </div>

        <div className={cl.container}>
          <div className={cl.row}>
            <ListingsList
              lastListingRef={lastListingRef}
              listingsData={listingsData}
            />
            {isFetching && metadata && metadata.offset > 0 && (
              <div className={cl.loadingDiv}>Loading...</div>
            )}
          </div>
        </div>
      </Container>
    </Loader>
  );
};

export default ListingsPage;
