import React, { useState } from 'react';
import styles from './TermsModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';

import {
  showModalSelector,
  showlinkSelector,
} from '../../../../redux/selectors/index';

import { setShowModal } from '../../../../redux/slices/eventTermsModalSlice/';

const TermsModal = () => {
  const showModal = useSelector(showModalSelector);
  const showLink = useSelector(showlinkSelector);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    return dispatch(setShowModal(false));
  };

  const handleAgreement = () => {
    window.open(showLink, '_blank');
    dispatch(setShowModal(false));
  };

  if (showModal) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  const render = () => {
    if (showModal) {
      return (
        <div>
          <div className={styles.TermsOverlay}></div>
          <div className={styles.TermsModalContainer}>
            <div className={styles.ModalSection}>
              <div className={styles.ModalTitle}>Listing Party Etiquette</div>
              <div className={styles.ModalDesc}>
                Violating these rules may result in you being escorted from the
                party.
              </div>{' '}
            </div>
            <div className={styles.terms}>
              <div className={styles.term}>
                <div className={styles.termTitle}>
                  1.No discussion of other services
                </div>
                <div className={styles.termDesc}>
                  No discussion of other services that may offer crossposting or
                  consignment services.
                </div>
              </div>
              <div className={styles.term}>
                <div className={styles.termTitle}>2.Communication</div>
                <div className={styles.termDesc}>
                  When communicating with other members, be professional and
                  polite. Offensive or inappropriate language, expectations,
                  demands, etc. is not allowed. Bashing and bullying in any
                  fashion will not be tolerated.
                </div>
              </div>
              <div className={styles.term}>
                <div className={styles.termTitle}>3.Be Kind and Courteous</div>
                <div className={styles.termDesc}>
                  Most importantly be kind and courteous. Not everyone will
                  agree with everything but it’s important to hear each other
                  out. Grace and kindness goes a long way.
                </div>
              </div>
            </div>
            <div className={styles.ModalButtons}>
              <div
                className={styles.cancelButton}
                onClick={() => handleCloseModal()}
              >
                Cancel
              </div>
              <div
                className={styles.acceptButton}
                onClick={() => handleAgreement()}
              >
                Accept and Join Party
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return render();
};

export default TermsModal;
