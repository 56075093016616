import React, { useEffect, useMemo, useRef, useState } from 'react';

import styles from './EditMessageModal.module.scss';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ModalButton from '../../../../../../components/ui/ModalButton/ModalButton';
import useUsers from '../../../../../../hooks/useUsers';
import { removeTags } from '../../../../../../utils/removeTags';

function EditMessageModal(props) {
  const { message, handleChangeEditable, id, item } = props;

  const { editMessage } = useUsers();

  const editMessageRef = useRef(null);

  const [editedMessage, setEditedMessage] = useState('');

  const modules = useMemo(() => {
    return {
      toolbar: [[false]],
      autoLinks: true,
    };
  }, []);

  const formats = ['link'];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editMessageRef.current &&
        !editMessageRef.current.contains(event.target)
      ) {
        handleChangeEditable();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editMessageRef]);

  useEffect(() => {
    setEditedMessage(message);
  }, []);

  function handleChange(value, delta, source, editor) {
    setEditedMessage(value.replace('<p><br></p>', ''));
  }

  const handleEditMessage = async () => {
    try {
      const response = await editMessage(
        { id },
        { message: removeTags(editedMessage) }
      );

      if (response.data) {
        item.message = response.data.message;
      }
    } catch (error) {
      console.error(error);
    }

    handleChangeEditable();
  };

  const handleKeyPress = (event) => {
    if ((event.key === 'Enter') & !event.shiftKey) {
      handleEditMessage(event);
    }
  };

  return (
    <div className={styles.editMessage__overlay}>
      <div ref={editMessageRef} className={styles.modal}>
        <h1>Edit Message</h1>
        <ReactQuill
          value={editedMessage}
          className={styles.reactQuill_textArea}
          modules={modules}
          formats={formats}
          onChange={handleChange}
          onKeyUp={handleKeyPress}
        />
        <div className={styles.modal__btns}>
          <ModalButton feature="light" cb={handleChangeEditable}>
            Discard
          </ModalButton>
          <ModalButton feature="primary" cb={handleEditMessage}>
            Save Changes
          </ModalButton>
        </div>
      </div>
    </div>
  );
}

export default EditMessageModal;
