import { useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  SVGarm,
  SVGfilterButton,
  SVGlogo,
  SVGprofilefont,
  SVGdirectoryfont,
  SVGinboxfont,
  SVGbarsfont,
  SVGpartiesfont,
  SVGlistingsfont,
} from '../../assets/PackageSVG';

import NavigationModule from './Modules/NavigationModule';
import ListingsFilterModule from './Modules/ListingsFilterModule';
import DirectoryFilterModule from './Modules/DirectoryFilterModule';

import cl from './MobileMenu.module.scss';
import { useSelector } from 'react-redux';
import { chatNotificationSelector } from '../../redux/selectors';

const LineLink = ({ to, name, children, ...rest }) => {
  const match = window.location.pathname.startsWith(to);
  const { pathname } = useLocation();

  const chatNotification = useSelector(chatNotificationSelector);

  return (
    <div className={cl.linkWrapper} {...rest}>
      <div className={[cl.item, match ? cl.active : ''].join(' ')}>
        <Link to={to}>
          <div className={cl.line}>
            {children}
            <span>{name}</span>
            {name === 'Inbox' &&
              chatNotification &&
              !pathname.includes('chat') && (
                <div className={cl.inboxNotification}></div>
              )}
          </div>
        </Link>
      </div>
    </div>
  );
};

const MobileMenu = ({
  blockState,
  aMenuId,
  isMobile,
  menuNotCollapsed,
  setMenuCollapsed,
}) => {
  const [isOpenBurger, setIsOpenBurger] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  //
  const navigate = useNavigate();

  //
  //
  //

  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    let lastState = true;

    const listener = () => {
      const newState =
        window.screen.height - 300 > window.visualViewport.height;
      if (lastState !== newState) {
        lastState = newState;
        setIsKeyboardOpen(newState);
      }
    };

    //

    window.visualViewport.addEventListener('resize', listener);

    return () => {
      window.visualViewport.removeEventListener('resize', listener);
    };
  }, []);

  //
  //
  //

  const openBurgerMenu = (value, e) => {
    if (value === null) value = !isOpenBurger;
    if (!e || e?.target === e?.currentTarget) setIsOpenBurger(value);
  };

  const handleClose = (tf) => {
    if (tf) return false;

    setIsOpenBurger(false);
    setIsOpenFilter(false);
  };

  const checkOneOf =
    blockState?.ban === true || blockState?.noUsername === true;

  const handleChangeResponsiveView = () => {
    const leftBox = document.querySelector('.leftBox');

    if (window.innerWidth < 768) {
      if (leftBox) {
        leftBox.style.display = 'block';
      }
    }
  };

  return (
    <>
      <div
        className={[
          cl.wrapper,
          isKeyboardOpen && (!isOpenBurger || !isOpenFilter) ? cl.hidde : '',
        ].join(' ')}
      >
        <div className={cl.menu}>
          {/* <LineLink
            to="/profile"
            name="Profile"
            onClick={() => handleClose(false)}
          >
            <SVGprofilefont />
          </LineLink> */}
          <LineLink
            to="/chat"
            name="Inbox"
            onClick={() => {
              handleClose(false);
              handleChangeResponsiveView();
            }}
          >
            <SVGinboxfont />
          </LineLink>

          {checkOneOf ? null : (
            <LineLink
              to="/directory"
              name="Directory"
              onClick={() => handleClose(false)}
            >
              <SVGdirectoryfont />
            </LineLink>
          )}
          <div className={cl.centredLogo}>
            <SVGlogo onClick={() => navigate('/')} />
          </div>
          {/* <LineLink
            to="/listings"
            name="Listings"
            onClick={() => handleClose(false)}
          >
            <SVGlistingsfont />
          </LineLink> */}
          {checkOneOf ? null : (
            <LineLink
              to="/events"
              name="Events"
              onClick={() => handleClose(false)}
            >
              <SVGpartiesfont />
            </LineLink>
          )}
          {/* <LineLink to='/inbox' name='Inbox'>
						<SVGinboxfont />
					</LineLink> */}
          <div className={cl.linkWrapper}>
            <div
              className={[cl.line, isOpenBurger ? cl.active : ''].join(' ')}
              onClick={() => openBurgerMenu(null)}
            >
              <SVGbarsfont />
              <span>Menu</span>
            </div>
          </div>
        </div>
        {isOpenBurger ? null : <div className={cl.rainbrow}></div>}
      </div>

      {isOpenBurger || isOpenFilter ? (
        <div
          className={cl.burgerMenuWrapper}
          onClick={({ target, currentTarget }) =>
            handleClose(target !== currentTarget)
          }
        >
          <div className={cl.burgerMenu}>
            <div className={cl.arm} onClick={() => handleClose()}>
              <SVGarm />
            </div>

            <div className={cl.body}>
              {isOpenFilter && aMenuId === 2 ? (
                <>
                  <DirectoryFilterModule
                    menuNotCollapsed={true}
                  ></DirectoryFilterModule>
                </>
              ) : isOpenFilter && aMenuId === 3 ? (
                <>
                  <ListingsFilterModule
                    menuNotCollapsed={true}
                  ></ListingsFilterModule>
                </>
              ) : (
                <>
                  <NavigationModule
                    checkOneOf={checkOneOf}
                    menuNotCollapsed={true}
                    isMobile={isMobile}
                    onClick={() => handleClose()}
                  ></NavigationModule>
                </>
              )}
            </div>
          </div>
        </div>
      ) : aMenuId === 2 || aMenuId === 3 ? (
        <div
          className={cl.filterButton}
          onClick={() => setIsOpenFilter(!isOpenFilter)}
        >
          <SVGfilterButton></SVGfilterButton>
        </div>
      ) : null}
    </>
  );
};

export default MobileMenu;
