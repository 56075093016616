import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSliderIndex } from '../../redux/slices/sliderSlice';
import Modal from '../Modal/Modal';
import ModalWindow from '../Modal/ModalWindow';
import styles from './Badge.module.scss';

export default function Badge({
  badgeItem,
  imgSrc,
  description,
  date,
  title,
  name,
  index,
  badgeLists,
  defaultLists,
}) {
  const [isModal, setIsModal] = useState(false);

  const dispatch = useDispatch();

  const [isModalWindow, setIsModalWindow] = useState(false);

  function onMouseOver() {
    setIsModal(true);
  }

  function onMouseOut() {
    setIsModal(false);
  }

  function handleModal() {
    setIsModalWindow(true);

    dispatch(setSliderIndex(index));
  }

  return (
    <>
      {isModalWindow && (
        <ModalWindow
          isProfile={true}
          isModalWindow={isModalWindow}
          setIsModalWindow={setIsModalWindow}
          badgeItem={title}
          name={name}
          badgeLists={badgeLists}
          defaultLists={defaultLists}
        />
      )}
      <div
        className={styles.badges}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={handleModal}
      >
        {/* <p className={styles.badgeContent}> {badgeItem}</p> */}

        <img src={imgSrc} alt="bird" />
        {isModal && <Modal badgeItem={title} isProfile={true} />}
      </div>
    </>
  );
}
