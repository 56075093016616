import React, { useState } from 'react';

import styles from '../../ChatMessages.module.scss';

import _ from 'lodash';

import EditMessageModal from '../EditMessageModal/EditMessageModal';
import ReportMessageModal from '../ReportMessageModal/ReportMessageModal';

function MessagesText({ item }) {
  const [editable, setEditable] = useState(false);
  const [reported, setReported] = useState(false);

  const handleChangeEditable = () => {
    setEditable(!editable);
  };

  const handleChangeReported = () => {
    setReported(!reported);
  };

  const partUserName = item.data ? item.data['username'] : '';

  return (
    <>
      <div className={styles.messageParent}>
        <div className={styles.partUserName}>
          <span>@{partUserName}</span>
        </div>
        <div
          className={styles.messageText}
          dangerouslySetInnerHTML={{ __html: _.unescape(item.message) }}
        />
        <div className={styles.messageTime}>
          <div className={styles.messageTime__wrapper}>
            {item.created_time} {' '}
            {/* {item.inComing ? (
                            <SVGmessageBlock onClick={handleChangeReported} />
                        ) : (
                            <SVGeditableMessage onClick={handleChangeEditable} />
                        )} */}
          </div>
        </div>
      </div>
      {editable && (
        <EditMessageModal
          message={_.unescape(item.message)}
          item={item}
          handleChangeEditable={handleChangeEditable}
          id={item.id}
        />
      )}
      {reported && (
        <ReportMessageModal
          message={_.unescape(item.message)}
          handleChangeReported={handleChangeReported}
        />
      )}
    </>
  );
}

export default MessagesText;
