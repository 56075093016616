import React from 'react';

import Tooltip from '../../../components/ui/tooltip/Tooltip';
import Checkbox from '../../../components/ui/checkbox/Checkbox';

import cl from './FormLocationPref.module.scss';

const FormLocationPref = ({
  locationPref,
  setLocationPref,
  setSaveBtnDisabled,
  type,
}) => {
  const labelLocationPref = ['Virtual', 'On-site'];
  return (
    <>
      <div className={cl.heading}>
        <div style={{ display: 'flex' }}>
          <h2>What are your location preferences?</h2>
          <Tooltip>
            <p>
              Would you like to work virtually i.e remotely from another
              location, or are you available to work onsite i.e. in the same
              physical space?
            </p>
          </Tooltip>
        </div>
        <p>Select all that apply.</p>
      </div>

      <div className={[cl.checkmarkBoxContainer, cl.pref].join(' ')}>
        {labelLocationPref.map((label) => (
          <Checkbox
            key={label}
            prefClass="prefClass"
            data={locationPref}
            setData={setLocationPref}
            {...{ setSaveBtnDisabled, type, label }}
          />
        ))}
      </div>
    </>
  );
};

export default FormLocationPref;
